import { useState, useEffect, useContext } from "react";
import mainContext from '../../context/context.js';
import TabbedPages from '../general/TabbedPages.js';
import TabbedPage from '../general/TabbedPage.js';
import CSSTextarea from '../general/CSSTextarea.js';
import ColorPicker from '../form/ColorPicker.js';

const SettingsDocument = ({doc, onUpdateDocument, snapToGrid, gridSize}) => {

  const { updateSnapToGrid, updateGridSize } = useContext(mainContext);

	const [_document, setDocument] = useState(doc);
	const [_hasChanged, setHasChanged] = useState(false);

	const [_snapToGrid, setSnapToGrid] = useState(snapToGrid);
	// const [_gridSize, setGridSize] = useState(gridSize);

	const [_gridV, setGridV] = useState(gridSize ? gridSize.v : 1);
	const [_gridH, setGridH] = useState(gridSize ? gridSize.h : 1);

  useEffect(() => {
    setDocument(doc);
  }, [doc]);

  useEffect(() => {
    setSnapToGrid(snapToGrid);
  }, [snapToGrid]);

  useEffect(() => {
    updateGridSize(_gridH, _gridV);
  }, [_gridV, _gridH]);

	const handleUpdateDocument = ({width, height, title, styles, bgColor}) => {

		let newDoc = _document;

		// if (width !== undefined) {
		// 	newDoc.width = parseInt(width);
		// }
		// if (height !== undefined) {
		// 	newDoc.height = parseInt(height);
		// }
		if (title !== undefined) {
			newDoc.title = title;
		}
		if (styles !== undefined) {
			newDoc.customStyles = styles;
		}
		if (bgColor !== undefined) {
			newDoc.backgroundColor = bgColor;
		}

		setDocument({...newDoc});

		setHasChanged(true);
	}

	const handleUpdateStyles = (styles) => {
		handleUpdateDocument({styles:styles});
	}

	const handleBGColorChange = (color) => {
		handleUpdateDocument({bgColor: color})
	}

	return <div className="content">


    <TabbedPages tabs={[
      { label: 'Document', id: 'document' },
	    { label: 'Grid', id: 'grid' },
      { label: 'Stylesheet (CSS)', id: 'stylesheet' }
    ]}>

			<TabbedPage key='document' tab='document'>

				<div className="flex flex--wrap">
					<div className="col-12">
						<div className="form-row">
							<label htmlFor="doc-title">Title</label>
							<input
								type="text"
								id="doc-title"
								value={_document.title}
								onChange={ (ev) => { handleUpdateDocument({title:ev.target.value}) }}
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="form-row">
							<label>Background Colour</label>
							<ColorPicker
								color={ doc.backgroundColor || '#fff' }
								colors={ doc.brandColors }
								onChangeComplete={ handleBGColorChange }
							/>
						</div>
					</div>
					{ /*
					<div className="col-6">
						<div className="form-row">
							<label htmlFor="width">Width</label>
							<input
								type="number"
								id="width"
								min={0}
								step={1}
								value={_document.width}
								onChange={ (ev) => { handleUpdateDocument({width:ev.target.value}) }}
							/>
						</div>
					</div>
					<div className="col-6">
						<div className="form-row">
							<label htmlFor="height">Height</label>
							<input
								type="number"
								id="height"
								min={0}
								step={1}
								value={_document.height}
								onChange={ (ev) => { handleUpdateDocument({height:ev.target.value}) }}
							/>
						</div>
					</div>
					*/ }
				</div>
				{ _hasChanged && <div className="btn-wrap text-right">
					<button className="btn btn-action" onClick={ () => { onUpdateDocument(_document) } }>Update</button>
				</div> }

			</TabbedPage>

			<TabbedPage key='grid' tab='grid'>

				<div className="flex flex--wrap">
					<div className="col-12">
						<div className="form-row">
              <label>Snap to Grid</label>
              <label htmlFor="l-snaptogrid" className="label-inline">
              <input
                type="checkbox"
                id="l-snaptogrid"
                checked={_snapToGrid}
                onChange={ (ev) => { updateSnapToGrid(ev.target.checked) }}
              /> <span>Enable snap to grid </span></label>
						</div>
					</div>

					{ snapToGrid && <>
						<div className="col-6">
							<div className="form-row">
								<label htmlFor="l-horizontal">Horizontal</label>
								<input
									type="number"
									id="l-horizontal"
									min={1}
									step={1}
									value={_gridH}
									onChange={ (ev) => { setGridH(ev.target.value) }}
								/>
							</div>
						</div>
						<div className="col-6">
							<div className="form-row">
								<label htmlFor="l-vertical">Vertical</label>
								<input
									type="number"
									id="l-vertical"
									min={0}
									step={1}
									value={_gridV}
									onChange={ (ev) => { setGridV(ev.target.value) }}
								/>
							</div>
						</div>
					</> }
				</div>

			</TabbedPage>

			<TabbedPage key='stylesheet' tab='stylesheet'>

				<CSSTextarea styles={_document.customStyles} onUpdate={ handleUpdateStyles }  />

			</TabbedPage>

		</TabbedPages>

	</div>;
};

export default SettingsDocument;

import { useState, useEffect } from "react";

const CSSTextarea = ({ styles = '', label = '', onUpdate = null }) => {

	const minRows = 10;

	const [_styles, setStyles] = useState(styles);
	const [_hasChanged, setHasChanged] = useState(false);
	const [_numRows, setNumRows] = useState(minRows);

  useEffect(() => {
		setStyles(styles);
	},[styles]);

  useEffect(() => {
		return () => {
			if (onUpdate) {
				onUpdate(_styles);
			}
		}
	},[]);

  useEffect(() => {

		setHasChanged(true);

		if (!_styles || _styles.length) {
			setNumRows(minRows);
			return;
		}

	  const lineCount = Math.max(_styles.split("\n").length, minRows);

		if (lineCount > minRows) {
			setNumRows(lineCount);
		}
		else {
			setNumRows(minRows);
		}

	},[_styles]);

	const handleDoUpdate = () => {
		onUpdate(_styles);
		setHasChanged(false);
	}

  return (
		<>
		<div className="form-row">
			<div className="css-textarea">
				<div className="line-numbers">
					{[...Array(_numRows)].map((e, i) => {
						return <div className="line-num" key={i}>{i+1}</div>
					})}
				</div>
			  <textarea
					value={_styles}
					onChange={(ev) => { setStyles(ev.target.value) }}
					rows={_numRows}
				></textarea>
			</div>
		</div>
		<div className="btn-wrap text-right">
			<button
				className="btn btn-action"
				disabled={!_hasChanged}
				onClick={ () => { handleDoUpdate() } }
			>Update</button>
		</div>
		</>
  );
}
export default CSSTextarea;

import { useApiPostJson, useApiPostForm } from './client-hook';

const imgURL = 'https://dev.brandmeadow.com/';
const sendUri = 'https://bm.taranstafford.co.uk/admin/save.php';
const previewUri = 'https://dev.brandmeadow.com/dev-tools/template-builder/post_screenshot.php';

export const useSave = (document, layers, send) => {
  const url = send ? sendUri : null;
	const allLayers = layers.getAll();
  var payload = { document, allLayers };
  return useApiPostJson(url, url === null ? null : payload);
};

export const useGetPreview = (document, layers, send) => {

	// https://dev.brandmeadow.com//post/post.layout_generator2.php?scale=1&client_id=414&w=600&h=315&design=design3&template=job_adverts_(letterbox)&page=page1&generate=true

	//https://dev.brandmeadow.com//post/post.layout_generator2.php?scale=1&client_id=5&w=630&h=300&design=design&template=error_template&page=page&generate=true

	// height 		: getUrlParameter('height'),
	// width  		: getUrlParameter('width'),
	// client_id 	: getUrlParameter('client_id'),
	// page 		: getUrlParameter('page'),
	// template 	: getUrlParameter('template'),
	// design 		: getUrlParameter('design'),
	// generate 	: 'true'

	// download url
	// https://dev.brandmeadow.com/view/brand_meadow/templates/error_template/page-designs/page/design/design.png

	const postData = {
		height 		: document.height,
		width  		: document.width,
		client_id : document.clientID,
		page 			: 'page1',
		template 	: document.doctypeSlug,
		design 		: document.id,
		generate 	: 'true'
	};

  const url = send ? previewUri : null;
  return useApiPostForm(url, url === null ? null : postData, {});
};

import { useCallback, useEffect, useReducer, useState } from 'react';

const useApi = (initialState, url, parseResponseAction, options = {}) => {

  const [stateOptions, setStateOptions] = useState(null);

  if (stateOptions === null) {
    setStateOptions(options);
  }
  else if (options.body != null && options.body.entries !== undefined) {
    if (stateOptions.body === null) {
      setStateOptions(options);
    }
    else if (stateOptions.body.entries !== undefined) {
      // if .entries exists on the body, we're dealing with FormData, so look through and compare values
      for (let [key, value] of options.body.entries()) {
        if (stateOptions.body.get(key) !== value) {
          setStateOptions(options);
          break;
        }
      }
    }
  }
  else if (options.body !== stateOptions.body) {
    setStateOptions(options);
  }

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, loading: true };
      case 'loaded':
        return {
          ...initialState,
          loading: false,
          data: action.response.data,
          status: action.response.status,
          statusText: action.response.statusText,
          success: true,
        };
      case 'error':
        return {
          ...initialState,
          loading: false,
          status: action.response.status,
          statusText: action.response.statusText,
          error: action.response.error,
          success: false,
        };
      default:
        return state;
    }
  }, initialState);

  async function fetchWithTimeout(url, options = {}) {
    const { timeout = 5000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });

    clearTimeout(id);

    return response;
  }

  useEffect(() => {
    // this allows to not make the request without having conditonal calls higher in the stack
    if (url === null || (stateOptions.method === 'POST' && stateOptions.body === null)) {
      return;
    }

    const makeRequest = async () => {

      dispatch({ type: 'loading' });

      try {
        const headers = { ...stateOptions.headers };
        const response = await fetchWithTimeout(url, {
          ...stateOptions,
          ...{ headers: headers },
        });

        if (response.status > 399) {
          const error = response.status === 400 ? await response.json() : 'Bad request';
          dispatch({
            type: 'error',
            response: {
              status: response.status,
              statusTest: response.statusText,
              error: error,
            },
          });
        }
        else {
          const data = await parseResponseAction(response);
          dispatch({
            type: 'loaded',
            response: {
              status: response.status,
              statusText: response.statusText,
              data: data,
            },
          });
        }
      }
      catch (error) {
        const createError = () => {
          if (error.name === 'AbortError') {
            return { message: 'Timeout talking to the server' };
          }

          if (error.message === 'Failed to fetch') {
            return { message: 'There was a problem talking to the server' };
          }

          return error;
        };

        dispatch({
          type: 'error',
          response: {
            error: createError(),
          },
        });
      }
    };

    makeRequest();
  }, [url, parseResponseAction, stateOptions]); // options.method, options.timeout, options.headers, options.body, options.form]);

  return state;
};


export const useApiGetJson = (url, isArray) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: isArray ? [] : {},
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    switch (response.status) {
      case 200:
        return response.json();
      case 204:
        return {};
      default:
        throw new Error('unexpected status code');
    }
  }, []);

  return useApi(initialState, url, parseResponse);
};

export const useApiPostForm = (url, data, headers) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: {},
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return response.json();
  }, []);

  let formData = new FormData();
  if (data) {
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }

  return useApi(initialState, url, parseResponse, {
    method: 'POST',
    body: formData,
    headers: headers || {},
  });
};

export const useApiPostJson = (url, data, headers) => {
  const initialState = {
    status: 0,
    statusText: '',
    data: {},
    error: null,
    loading: false,
    success: false,
  };

  const parseResponse = useCallback((response) => {
    return response.json();
  }, []);

  return useApi(initialState, url, parseResponse, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: headers || {
      'Content-Type': 'application/json',
    },
  });
};

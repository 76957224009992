import { useState, useEffect } from "react";

const ToggleButton = ({value, onValue = true, onUpdate = null, iconOn = 'On', iconOff = 'Off', disableToggle = false}) => {

	const [_on, setOn] = useState(value === onValue);

	useEffect(() => {
		setOn(value === onValue);
	}, [value]);

	const handleClick = () => {
		if (disableToggle) {
			if (!_on) {
				setOn(true);
				if (onUpdate) {
					onUpdate(true);
				}
			}
		}
		else {
			setOn(!_on);
			onUpdate(!_on);
		}
	}

	return <button
		className={ "btn-toggle btn-toggle--" + (_on ? 'on' : 'off') }
		onClick={handleClick}
	>
		{_on ? iconOn : iconOff}
	</button>;
}
export default ToggleButton;

import { useState, useEffect, useContext } from "react";
import parse from 'html-react-parser';
import mainContext from '../../context/context.js';
import {hexToRgba} from '../../Utils.js';


const LayerObject = ({ obj, parentObj = null, isResizing = false, isDragging = false, dragOffset = null, resizeDirection = null, currentGroup = null, selectedObjects = null }) => {

  const { onObjectHover, onObjectSelect, fontForID } = useContext(mainContext);
  const isEditing = currentGroup && currentGroup === obj.id;
  const isSelected = selectedObjects.includes(obj.id);
	const isEditingClass = (isEditing) ? ' is-editing' : '';
	const isSelectedClass = (isSelected) ? ' is-selected' : '';

	const [_data, setData] = useState(new Map());

	const updateData = (key, val) => {
		let data = _data;
		data.set(key, val);
		setData(new Map(data));
	}

	// set initial data
	useEffect(() => {
		if (obj.type === 'TEXT') {
			updateData('value', obj.TEXT.value);
		}
  }, [obj]);

	// common styles
	let style = {
    left:obj.x,
		top:obj.y,
		width:obj.width,
		height:obj.height,
		padding:obj.padding || '',
    backgroundColor: (obj.bgOpacity !== 1) ? hexToRgba(obj.background, obj.bgOpacity) : obj.background,
    borderColor: obj.borderColor,
    borderStyle: obj.borderWidth ? 'solid' : '',
    borderWidth: obj.borderWidth,
    borderRadius: obj.borderRadius,
  }

  const calcN = (b) => {
    const orgHeight = b.height;
    b.y = (b.height - dragOffset.y >= 0) ? b.y + dragOffset.y : b.y + orgHeight;
    b.height -= dragOffset.y;
    b.height = Math.max(b.height, 0);
  }
  const calcS = (b) => {
    b.height += dragOffset.y;
    b.height = Math.max(b.height, 0);
  }
  const calcW = (b) => {
    const orgWidth = b.width;
    b.x = (b.width - dragOffset.x >= 0) ? b.x + dragOffset.x : b.x + orgWidth;
    b.width -= dragOffset.x;
    b.width = Math.max(b.width, 0);
  }
  const calcE = (b) => {
    b.width += dragOffset.x;
    b.width = Math.max(b.width, 0);
  }

  if (isDragging && selectedObjects.includes(obj.id)) {
    // resizing
    if (resizeDirection) {

      let bounds = {
        x:obj.x,
        y:obj.y,
        width:obj.width,
        height:obj.height
      }

      switch(resizeDirection) {
        case 'n':
          calcN(bounds);
          break;
        case 's':
          calcS(bounds);
          break;
        case 'w':
          calcW(bounds);
          break;
        case 'e':
          calcE(bounds);
          break;
        case 'nw':
          calcN(bounds);
          calcW(bounds);
          break;
        case 'ne':
          calcN(bounds);
          calcE(bounds);
          break;
        case 'sw':
          calcS(bounds);
          calcW(bounds);
          break;
        case 'se':
          calcS(bounds);
          calcE(bounds);
          break;
      }

    	style.top = bounds.y;
      style.left = bounds.x;
      style.width = bounds.width;
      style.height = bounds.height;
    }
    // moving
    else {
      let offX = 0, offY = 0;

      if (dragOffset) {
        offX = dragOffset.x;
        offY = dragOffset.y;
      }

      style.left = obj.x + offX;
    	style.top = obj.y + offY;
    }
	}

	let contentEditing = null;
	let contentStatic = null;

	// type specific
	switch (obj.type) {
		case 'TEXT' :

			// Custom styles
			style = {
				fontSize:(obj.TEXT.fontSize) ? obj.TEXT.fontSize : 16,
  			lineHeight:(obj.TEXT.lineHeight) ? obj.TEXT.lineHeight : '',
				padding: (obj.TEXT.padding) ? obj.TEXT.padding : '0',
				color: (obj.TEXT.color) ? obj.TEXT.color : '#000',
				...style
			};

      let txt = _data.get('value') || '';
      txt = txt.replaceAll('\n', '<br>');

      if (obj.TEXT.fontFamily !== '') {
        const font = fontForID(obj.TEXT.fontFamily);
        if (font) {
          style.fontFamily = font.family;
          style.fontWeight = font.weight;
        }
      }

      if (obj.TEXT.editorType === 'static' || obj.TEXT.editorType === 'basic') {
        style.textAlign = (obj.TEXT.textAlign) ? obj.TEXT.textAlign : 'left';

        if (obj.TEXT.isBold) {
          txt = `<b>${txt}</b>`;
        }
        if (obj.TEXT.isItalic) {
          txt = `<i>${txt}</i>`;
        }
        if (obj.TEXT.isUnderlined) {
          txt = `<u>${txt}</u>`;
        }
      }

			// Static content
			contentStatic = <div className="noselect">{(txt && txt !== '') ? parse(txt) : <span className="placeholder-text">{obj.TEXT.placeholder}</span> }</div>;

			// Editable content
			// contentEditing = <textarea
			// 	value={_data.get('value') || ''}
			// 	onChange={(ev) => { updateData('value',ev.target.value) }}
			// 	placeholder={obj.TEXT.placeholder}
			// 	style={{
			// 		fontSize: (obj.TEXT.fontSize) ? obj.TEXT.fontSize : '16px',
			// 		color: (obj.TEXT.color) ? obj.TEXT.color : '#000',
			// 	}}
			// />;

      contentEditing = contentStatic;

			break;
		case 'IMAGE' :
			style = {
				backgroundImage:(obj.IMAGE.src !== '') ? 'url('+obj.IMAGE.src+')' : '',
				backgroundRepeat:'no-repeat',
				backgroundSize:obj.IMAGE.size,
				backgroundPosition:obj.IMAGE.position,
				...style
			}
			break;
		case 'SHAPE' :
			style = {

				...style
			}
			break;
		case 'GRUOP' :
			style = {

				...style
			}
			break;
	}

	return <div
		className={"object object--"+obj.type.toLowerCase()+isSelectedClass+isEditingClass}
		onMouseDown={(e) => { e.stopPropagation(); onObjectSelect(obj.id, e) }}
		onMouseEnter={() => { onObjectHover(obj.id) }}
		onMouseLeave={() => { onObjectHover(null) }}
		style={style}
    id={obj.id}
		data-id={obj.id}
	>
  	{ !isEditing &&
  		contentStatic
  	}
  	{ isEditing &&
  		contentEditing
  	}
    { obj.children && <div className="object__children">
    {
      obj.children.map(child => {
        return <LayerObject
          key={child.id}
          obj={child}
          parentObj={obj}
          isDragging={isDragging}
          dragOffset={dragOffset}
          resizeDirection={resizeDirection}
          currentGroup={currentGroup}
          selectedObjects={selectedObjects}
        />
      })
    }</div> }
	</div>;
};

export default LayerObject;

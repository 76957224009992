import { useState, useEffect } from "react";

import LayerObject from './objects/LayerObject';
import ResizeTools from './objects/ResizeTools';
import GuideLines from './objects/GuideLines';

const Artboard = ({ doc, objects, selectedObjects, selectedBoundary, hoverObject, isDragging = false, currentGroup = null, dragOffset, resizeDirection, snapToGrid, gridSize }) => {

	const [_document, setDocument] = useState(doc);

	const [_selectedObjects, setSelectedObjects] = useState(selectedObjects);
	const [_hoverObject, setHoverObject] = useState(hoverObject);

	const [_boundry, setBoundry] = useState(selectedBoundary);

	const [_objects, setobjects] = useState(objects);

	// listen for changes of layer object
  useEffect(() => {
    setobjects(objects);
  }, [objects]);

  useEffect(() => {
    setDocument(doc);
  }, [doc]);

  useEffect(() => {
	  setSelectedObjects(selectedObjects);
  }, [selectedObjects]);

  useEffect(() => {
	  setHoverObject(hoverObject);
  }, [hoverObject]);

  useEffect(() => {
		setBoundry(selectedBoundary);
	}, [selectedBoundary]);

	const isEditingClass = (currentGroup) ? ' is-editing' : '';

	return <div className={"artboard"+isEditingClass} style={{width:_document.width + 160}}>
		<div className="artboard__inner"
			id="artboard"
			style={{
				width:_document.width,
				height:_document.height,
				backgroundColor: doc.backgroundColor || '#fff'
			}}
		>

			<div className="objects">
				<div className="clicktrap"></div>
				{
					_objects.map(l => {
						return <LayerObject
							key={l.id}
							obj={l}
							isDragging={isDragging}
							dragOffset={dragOffset}
							resizeDirection={resizeDirection}
							currentGroup={currentGroup}
							selectedObjects={_selectedObjects}
						/>
					})
				}
			</div>

			{
				selectedObjects.length === 1 && _boundry && <GuideLines boundry={_boundry} />
			}

			<div className="guides">
				{
					selectedObjects.length && _boundry ? <ResizeTools
						boundry={_boundry}
						isDragging={isDragging}
						isResizing={resizeDirection}
					/> : null
				}
			</div>

		</div>
	</div>;
};

export default Artboard;

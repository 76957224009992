import { Editor, Element } from "slate";
import { Point, Range, Text, Transforms } from "slate";
import { jsx } from 'slate-hyperscript';

//import isUrl from "is-url";

export function getActiveStyles(editor) {
  return new Set(Object.keys(Editor.marks(editor) ?? {}));
}

export function getTextBlockStyle(editor) {
  const selection = editor.selection;
  if (selection == null) {
    return null;
  }

  const topLevelBlockNodesInSelection = Editor.nodes(editor, {
    at: editor.selection,
    mode: "highest",
    match: (n) => Editor.isBlock(editor, n),
  });

  let blockType = null;
  let nodeEntry = topLevelBlockNodesInSelection.next();
  while (!nodeEntry.done) {
    const [node] = nodeEntry.value;
    if (blockType == null) {
      blockType = node.type;
    } else if (blockType !== node.type) {
      return "multiple";
    }

    nodeEntry = topLevelBlockNodesInSelection.next();
  }

  return blockType !== "image" ? blockType : null;
}

export function toggleStyle(editor, style) {
  const activeStyles = getActiveStyles(editor);
  if (activeStyles.has(style)) {
    Editor.removeMark(editor, style);
  } else {
    Editor.addMark(editor, style, true);
  }
}

export function toggleBlockType(editor, blockType) {
  const currentBlockType = getTextBlockStyle(editor);
  const changeTo = currentBlockType === blockType ? "paragraph" : blockType;
  Transforms.setNodes(
    editor,
    { type: changeTo },
    { at: editor.selection, match: (n) => Editor.isBlock(editor, n) }
  );
}


function renderChildren(children) {
  let html = '';

  children.forEach((el) => {

    if (el.text || el.text === '') {
      let span = el.text;

      if (el.bold) {
        span = `<strong>${span}</strong>`;
      }
      if (el.italic) {
        span = `<em>${span}</em>`;
      }
      if (el.underline) {
        span = `<u>${span}</u>`;
      }
      html += `${span}`;
    }
    else {
      if (el.type) {
        html += renderElement(el);
      }
    }

  });

  return html;
}

function renderElement(el) {
  switch (el.type) {
    case "paragraph":
      return `<p>${renderChildren(el.children)}</p>`;
    case "h1":
      return `<h1>${renderChildren(el.children)}</h1>`;
    case "h2":
      return `<h2>${renderChildren(el.children)}</h2>`;
    case "h3":
      return `<h3>${renderChildren(el.children)}</h3>`;
    case "h4":
      return `<h4>${renderChildren(el.children)}</h4>`;
		case "img":
      return `<img src=${el.src} width=${el.width} height=${el.height} alt=${el.alt} />`;
		case "figure":
      return `<figure>${renderChildren(el.children)}</figure>`;
    case "figcaption":
      return `<figcaption>${renderChildren(el.children)}</figcaption>`;
		case "iframe":
      return `<iframe src="${el.src}" width="${el.width}" height="${el.height}" loading="${el.loading}" title="${el.title}" frameborder="${el.frameborder}" allow="${el.allow}" allowfullscreen="${el.allowfullscreen}" ></iframe>`;
		case "blockquote":
      return `<blockquote>${renderChildren(el.children)}</blockquote>`;
		case "cite":
      return `<cite>${renderChildren(el.children)}</cite>`;
		case "code":
      return `<pre><code>${renderChildren(el.children)}</code></pre>`;
    }
}

export function toHTML(doc) {
  let html = '';

  // return empty string if blank
  if (doc.length === 1
    && doc[0].children.length === 1
    && doc[0].children[0].text === ''
  ) {
    return '';
  }

  doc.forEach((el) => {
    html += renderElement(el);
  });

  return html;
}

export const parseHTML = (el, markAttributes = {}) => {

  if (el.nodeType === Node.TEXT_NODE) {
    return jsx('text', markAttributes, el.textContent)
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null
  }

  const nodeAttributes = { ...markAttributes }

  // define attributes for text nodes
  switch (el.nodeName) {
    case 'B':
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'I':
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
      break;
  }

  const children = Array.from(el.childNodes)
    .map(node => parseHTML(node, nodeAttributes))
    .flat()

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''))
  }

  switch (el.nodeName) {
    // case 'DIV':
    //   return jsx('element', { type: 'div', class: el.getAttribute('class') }, children)
    // case 'BODY':
    //   return jsx('fragment', {}, children)
    case 'FIGURE':
      return jsx('element', { type: 'figure' }, children)
    case 'IFRAME':
      return jsx('element', {
        type: 'iframe',
        loading: el.getAttribute('loading'),
        title: el.getAttribute('title'),
        src: el.getAttribute('src'),
        width: el.getAttribute('width'),
        height: el.getAttribute('height'),
        frameborder: el.getAttribute('frameborder'),
        allow: el.getAttribute('allow'),
        allowfullscreen: el.getAttribute('allowfullscreen'),
      }, children)
    case 'BR':
      return '\n'
    case 'BLOCKQUOTE':
      return jsx('element', { type: 'blockquote' }, children)
    case 'CITE':
      return jsx('element', { type: 'cite' }, children)
    case 'IMG':
      return jsx('element', {
        type: 'img',
        src: el.getAttribute('src'),
        width: el.getAttribute('width'),
        height: el.getAttribute('height'),
        alt: el.getAttribute('alt'),
      }, children)
    case 'FIGCAPTION':
      return jsx('element', { type: 'figcaption' }, children)
    case 'P':
      return jsx('element', { type: 'paragraph' }, children)
    case 'H1':
      return jsx('element', { type: 'h1' }, children)
    case 'H2':
      return jsx('element', { type: 'h2' }, children)
    case 'H3':
      return jsx('element', { type: 'h3' }, children)
    case 'H4':
      return jsx('element', { type: 'h4' }, children)
    case 'H5':
      return jsx('element', { type: 'h5' }, children)
    case 'H6':
      return jsx('element', { type: 'h6' }, children)
    // case 'A':
    //   return jsx(
    //     'element',
    //     {
    //       type: 'link',
    //       href: el.getAttribute('href'),
    //       title: el.getAttribute('title'),
    //       target: el.getAttribute('target'),
    //       rel: el.getAttribute('rel'),
    //       erdgf: el.getAttribute('erdgf')
    //     },
    //     children
    //   )
    default:
      return children
  }
}


// export function hasActiveLinkAtSelection(editor) {
//   return isLinkNodeAtSelection(editor, editor.selection);
// }
//
// export function toggleLinkAtSelection(editor) {
//   if(editor.selection == null) {
//     return;
//   }
//
//   if (hasActiveLinkAtSelection(editor)) {
//     Transforms.unwrapNodes(editor, {
//       match: (n) => Element.isElement(n) && n.type === "link",
//     });
//   } else {
//     const isSelectionCollapsed =
//       editor.selection == null || Range.isCollapsed(editor.selection);
//     if (isSelectionCollapsed) {
//       createLinkForRange(editor, null, "link", "", true /*isInsertion*/);
//     } else {
//       createLinkForRange(editor, editor.selection, "", "");
//     }
//   }
// }

// export function isLinkNodeAtSelection(editor, selection) {
//   if (selection == null) {
//     return false;
//   }
//
//   return (
//     Editor.above(editor, {
//       at: selection,
//       match: (n) => n.type === "link",
//     }) != null
//   );
// }

// export function identifyLinksInTextIfAny(editor) {
//   // if selection is not collapsed, we do not proceed with the link detection.
//   if (editor.selection == null || !Range.isCollapsed(editor.selection)) {
//     return;
//   }
//
//   const [node] = Editor.parent(editor, editor.selection);
//   // if we are already inside a link, exit early.
//   if (node.type === "link") {
//     return;
//   }
//
//   const [currentNode, currentNodePath] = Editor.node(editor, editor.selection);
//   if (!Text.isText(currentNode)) {
//     return;
//   }
//
//   let [start] = Range.edges(editor.selection);
//   const cursorPoint = start;
//
//   const startPointOfLastCharacter = Editor.before(editor, editor.selection, {
//     unit: "character",
//   });
//
//   let lastCharacter = Editor.string(
//     editor,
//     Editor.range(editor, startPointOfLastCharacter, cursorPoint)
//   );
//
//   if (lastCharacter !== " ") {
//     return;
//   }
//
//   let end = startPointOfLastCharacter;
//   start = Editor.before(editor, end, {
//     unit: "character",
//   });
//
//   const startOfTextNode = Editor.point(editor, currentNodePath, {
//     edge: "start",
//   });
//
//   lastCharacter = Editor.string(editor, Editor.range(editor, start, end));
//
//   while (lastCharacter !== " " && !Point.isBefore(start, startOfTextNode)) {
//     end = start;
//     start = Editor.before(editor, end, { unit: "character" });
//     lastCharacter = Editor.string(editor, Editor.range(editor, start, end));
//   }
//
//   const lastWordRange = Editor.range(editor, end, startPointOfLastCharacter);
//   const lastWord = Editor.string(editor, lastWordRange);
//
//   if (isUrl(lastWord)) {
//     Promise.resolve().then(() =>
//       createLinkForRange(editor, lastWordRange, lastWord, lastWord)
//     );
//   }
// }

// function createLinkForRange(editor, range, linkText, linkURL, isInsertion) {
//   isInsertion
//     ? Transforms.insertNodes(
//         editor,
//         {
//           type: "link",
//           url: linkURL,
//           children: [{ text: linkText }],
//         },
//         range != null ? { at: range } : undefined
//       )
//     : Transforms.wrapNodes(
//         editor,
//         { type: "link", url: linkURL, children: [{ text: linkText }] },
//         { split: true, at: range }
//       );
// }

import Stage from './components/Stage';

const App = () => {

  return (
    <Stage />
  );
}

export default App;

import { useState, useEffect, useContext } from "react";
import { Tooltip } from 'react-tooltip';

import mainContext from '../context/context.js';
import LayerItem from './LayerItem';

const Layers = ({ layers, selectedObjects = [], currentGroup = null, hoverObject}) => {

	const { onDeleteSelected, onCloneSelected, onGroupSelected } = useContext(mainContext);

	const [_layers, setLayers] = useState(layers);

  useEffect(() => {
    setLayers(layers);
  }, [layers]);

	const maxLayers = _layers.getAll().length;

	const layerRow = (lobj, index, level, parent, max) => {
		const row = <LayerItem
			key={lobj.id}
			obj={lobj}
			index={max - index - 1}
			level={level}
			max={max}
			isSelected={selectedObjects.includes(lobj.id) || currentGroup === lobj.id}
			isGroupSelected={currentGroup === lobj.parentLayer}
			isHovering={hoverObject === lobj.id}
		/>;

		let childRows = [];

		if (lobj.children && lobj.children.length) {
			childRows = lobj.children.reverse().map((child, c_index) => {
				return layerRow(child, c_index, level+1, lobj, lobj.children.length);
			});
		}

		return [row, ...childRows];
	}

	return <div className="layers">
		<div className="layers__items">
			{
				[..._layers.getAll()].reverse().map((l, index) => {

					return layerRow(l, index, 0, null, maxLayers);

				})
			}
		</div>
		<div className="layers__tools">

			<div className="btn-wrap text-right">

				{selectedObjects.length > 1 && <>
					<button
						id="btn-group-layer"
						className="btn btn-inline"
						onClick={onGroupSelected}
					>
						<i className="icon-layer-group"></i><span className="visually-hidden">Group layers</span>
					</button>
					<Tooltip anchorId="btn-group-layer" content="Group layers" place="top" />
				</> }

				<button
					id="btn-clone-layer"
					className="btn btn-inline"
					disabled={!selectedObjects.length}
					onClick={onCloneSelected}
				>
					<i className="icon-clone"></i><span className="visually-hidden">Clone layer</span>
				</button>
				<Tooltip anchorId="btn-clone-layer" content="Clone layer" place="top" />

				<button
					id="btn-delete-layer"
					className="btn btn-inline"
					disabled={!selectedObjects.length}
					onClick={onDeleteSelected}
				>
					<i className="icon-trash-empty"></i><span className="visually-hidden">Delete layer</span>
				</button>
				<Tooltip anchorId="btn-delete-layer" content="Delete layer" place="top" />
			</div>

		</div>
	</div>;
};

export default Layers;


const layerObject = (data) => {
	return {
		id: data.id || null,
		x: data.x || 0,
		y: data.y || 0,
		width: data.width || 0,
		height: data.height || 0,
		padding: data.padding || '',
		customStyles: data.customStyles || '',
		hidden: data.hidden || false,
		opacity: data.opacity || 1,
		canBeDeleted: true,
		editable: data.editable !== undefined ? data.editable : true,
		title: data.title || '',
		tooltip: data.tooltip || '',
		background: data.background || '#ffffff',
		bgOpacity: data.bgOpacity !== 1 ? data.bgOpacity : 1,
		borderColor: data.borderColor || '#ffffff',
		borderWidth: data.borderWidth || 0,
		borderRadius: data.borderRadius || '',
		revision: 1,
		parentLayer: data.parentLayer || null,
	};
}

// Text
export const layerTextObject = (data) => {

	let obj = layerObject(data);

	obj.bgOpacity = data.bgOpacity || 0;

	let text = {
		editorType: data.TEXT && data.TEXT.editorType || 'basic',
		value: data.TEXT && data.TEXT.value || '',
		placeholder: data.TEXT && data.TEXT.placeholder || 'Enter text here...',
		// background: data.TEXT && data.TEXT.background || 'transparent',
		isSingleLine: data.TEXT && data.TEXT.isSingleLine || false,
		color: data.TEXT && data.TEXT.color || '#000',
		fontSize: data.TEXT && data.TEXT.fontSize || 20,
		lineHeight: data.TEXT && data.TEXT.lineHeight || 1.2,
		fontFamily: data.TEXT && data.TEXT.fontFamily || '',
		fontWeight: data.TEXT && data.TEXT.fontWeight || '',
		isBold: data.TEXT && data.TEXT.isBold || false,
		isItalic: data.TEXT && data.TEXT.isItalic || false,
		isUnderlined: data.TEXT && data.TEXT.isUnderlined || false,
		textAlign: data.TEXT && data.TEXT.textAlign || 'left',
		textDecoration: data.TEXT && data.TEXT.textDecoration || 'none',
		padding: data.TEXT && data.TEXT.padding || '',
	}

	return {
		type: 'TEXT',
		title: data.title || 'Text layer',
		TEXT: text,
		...obj
	}
}

// Image
export const layerImageObject = (data) => {

	let obj = layerObject(data);

	let image = {
		src: data.IMAGE && data.IMAGE.src || '',
		position: data.IMAGE && data.IMAGE.position || 'center',
		size: data.IMAGE && data.IMAGE.size || 'cover',
		isBackground: data.IMAGE && data.IMAGE.isBackground || false,
		gallery: data.IMAGE && data.IMAGE.gallery || '',
		canUpload: data.IMAGE && data.IMAGE.canUpload || false,
		disableGallery: data.IMAGE && data.IMAGE.disableGallery || false,
		disableSlider: data.IMAGE && data.IMAGE.disableSlider || false,
		isBackground: data.IMAGE && data.IMAGE.isBackground || false,
		bgX: data.IMAGE && data.IMAGE.bgX || '',
		bgY: data.IMAGE && data.IMAGE.bgY || '',
		bgWidth: data.IMAGE && data.IMAGE.bgWidth || '',
		bgHeight: data.IMAGE && data.IMAGE.bgHeight || '',
	}

	return {
		...obj,
		type: 'IMAGE',
		title: data.title || 'Image layer',
		IMAGE: image,
	}
}

// List
export const layerListObject = (data) => {

	let obj = layerObject(data);

	return {
		...obj,
		type: 'LIST',
		title: data.title || 'List layer',
	}
}

// Shape
export const layerShapeObject = (data) => {

	let obj = layerObject(data);

	return {
		...obj,
		type: 'SHAPE',
		background: data.background || '#77b729',
		bgOpacity: data.bgOpacity || 1,
		editable: false,
		borderRadius: data.borderRadius || '50%',
		title: data.title || 'Shape layer',
	}
}

// Group
export const layerGroupObject = (data) => {

	let obj = layerObject(data);

	return {
		...obj,
		type: 'GROUP',
		title: data.title || 'Group layer',
		children: data.children || [],
	}
}

// Repeater
export const layerRepeaterObject = (data) => {

	let obj = layerObject(data);

	return {
		...obj,
		type: 'REPEATER',
		title: data.title || 'Repeater layer',
	}
}

// Get all
export const getLayerObject = (data, type) => {
	switch (type) {
		case 'TEXT':
			return layerTextObject(data);
		case 'IMAGE':
			return layerImageObject(data);
		case 'SHAPE':
			return layerShapeObject(data);
		case 'GROUP':
			return layerGroupObject(data);
		case 'LIST':
			return layerListObject(data);
		case 'REPEATER':
			return layerRepeaterObject(data);
	}
	return layerObject(data);
}

import { useState, useEffect, useContext } from "react";
import mainContext from '../../context/context.js';

import Editor from '../wysiwyg/Editor.js';
import { parseHTML } from '../wysiwyg/utils/EditorUtils.js';

import ToggleButton from '../form/ToggleButton.js';
import ColorPicker from '../form/ColorPicker.js';

const SettingsImage = ({layer, doc}) => {

  const { onUpdateSelected, fontForID } = useContext(mainContext);

  const [_editorType, setEditorType] = useState(layer.TEXT.editorType);

  const [_isSingleLine, setIsSingleLine] = useState(layer.TEXT.isSingleLine);

  const [_fontFamily, setFontFamily] = useState(layer.TEXT.fontFamily !== '' ? layer.TEXT.fontFamily : doc.baseFont);
  const [_color, setFontColor] = useState(layer.TEXT.color);
  const [_fontSize, setFontSize] = useState(layer.TEXT.fontSize !== '' ? layer.TEXT.fontSize : doc.baseFontSize);
  // const [_fontWeight, setFontWeight] = useState(layer.TEXT.fontWeight !== '' ? layer.TEXT.fontWeight : doc.baseFontWeight);
  const [_lineHeight, setLineHeight] = useState(layer.TEXT.lineHeight);

  const [_textAlign, setTextAlign] = useState(layer.TEXT.textAlign);
  const [_isBold, setIsBold] = useState(layer.TEXT.isBold);
  const [_isItalic, setIsItalic] = useState(layer.TEXT.isItalic);
  const [_isUnderlined, setIsUnderlined] = useState(layer.TEXT.isUnderlined);

	const [_text, setText] = useState(layer.TEXT.value);
  const [_placeholder, setPlaceholder] = useState(layer.TEXT.placeholder);

  // WYSIWYG editor

  // TODO - improve this function!!
  const textToDom = () => {
    const html = _text.replace(/(?:\r\n|\r|\n)/g, '');
    const document = new DOMParser().parseFromString(html, 'text/html');
    const obj = parseHTML(document.body);

    // if just text wrap in Paragraph
    if (obj.length && obj[0].hasOwnProperty('text')) {
      return [
    		{
    	    type: 'paragraph',
    	    children: [
    				{ text: _text }
    			],
    	  }
    	];
    }

    return obj;
  }
  // initial value - can't render without this
	const initialValue = [
		{
	    type: 'paragraph',
	    children: [
				{ text: '' }
			],
	  }
	];
  // holds editor state
	const [_editorState, setEditorState] = useState(initialValue);
  // update states on change
	const handleMessageChange = (doc, html) => {
		// HTML string
		setText(html);
		setEditorState(doc);
	}
  // initial load
  useEffect(() => {
    setEditorState(textToDom());
  }, []);

  // listen for text changes and update layer
  // TODO - reduce history points?
  useEffect(() => {
    updateLayer();
  }, [_text]);

  // Listen for editor state
  useEffect(() => {
    // if wysiwyg - update editor state
    if (_editorType === 'wysiwyg') {
      const dom = textToDom();
      setEditorState(dom);
    }
    // if basic - add linebreaks / or trip tags
    else if (_editorType === 'basic') {
      // strip tags
      let strippedString = _text.replaceAll('</p><p>', "</p>\n<p>");
      strippedString = strippedString.replace(/(<([^>]+)>)/gi, "");
      setText(strippedString);
    }
  }, [_editorType]);

  // fonts
  const currFont = fontForID(_fontFamily);

  // listen for changes
  useEffect(() => {

		setText(layer.TEXT.value);
  	setPlaceholder(layer.TEXT.placeholder);
  	setIsSingleLine(layer.TEXT.isSingleLine);

    setEditorType(layer.TEXT.editorType);
    setFontFamily(layer.TEXT.fontFamily !== '' ? layer.TEXT.fontFamily : doc.baseFont);
    setFontColor(layer.TEXT.color);
    setFontSize(layer.TEXT.fontSize !== '' ? layer.TEXT.fontSize : doc.baseFontSize);
    setLineHeight(layer.TEXT.lineHeight);

    setTextAlign(layer.TEXT.textAlign);
    setIsBold(layer.TEXT.isBold);
    setIsItalic(layer.TEXT.isItalic);
    setIsUnderlined(layer.TEXT.isUnderlined);

  }, [layer]);

  // on blur update layers object
	const updateLayer = () => {
		let updated = {...layer};

    updated.TEXT.editorType = _editorType;

    updated.TEXT.fontFamily = _fontFamily;
    updated.TEXT.fontSize = parseInt(_fontSize);
    //updated.TEXT.fontWeight = _fontWeight;
    updated.TEXT.lineHeight = _lineHeight;
    updated.TEXT.color = _color;

		updated.TEXT.value = _text;
    updated.TEXT.isSingleLine = _isSingleLine;
  	updated.TEXT.placeholder = _placeholder;

  	updated.TEXT.placeholder = _placeholder;
  	updated.TEXT.placeholder = _placeholder;
  	updated.TEXT.placeholder = _placeholder;

  	updated.TEXT.textAlign = _textAlign;
  	updated.TEXT.isBold = _isBold;
  	updated.TEXT.isItalic = _isItalic;
  	updated.TEXT.isUnderlined = _isUnderlined;

		onUpdateSelected(updated);
	}

  // listen for on change events
  useEffect(() => {
		updateLayer();
  }, [_textAlign,_isBold,_isItalic,_isUnderlined,_editorType,_color]);

  useEffect(() => {
		updateLayer();
  }, [_fontFamily]);

	return <div className="content">
		<div className="flex flex--wrap">

      <div className="col-12">
        <div className="form-row">
          <label htmlFor="size">Editor Type</label>
          <select
            id="size"
            value={_editorType}
            onChange={ (ev) => { setEditorType(ev.target.value); }}
          >
            {/* <option value="static">Static</option> */}
            <option value="basic">Basic</option>
            <option value="wysiwyg">WYSIWYG</option>
          </select>
        </div>
      </div>

      <div className="col-12">
        <div className="form-row">
          <label>Single Line</label>
          <label htmlFor="title" className="label-inline">
          <input
            type="checkbox"
            id="title"
            checked={_isSingleLine}
            onChange={ (ev) => { setIsSingleLine(ev.target.checked) }}
            onBlur={updateLayer}
          /> <span>This will limit text input to a single line.</span></label>
        </div>
      </div>

      <div className="col-6">
        <div className="form-row">
          <label htmlFor="width">Font Size (px)</label>
          <input
            type="number"
            id="width"
            min={0}
            step={1}
            value={_fontSize}
            onChange={(ev) => { setFontSize(ev.target.value) }}
            onBlur={updateLayer}
          />
        </div>
      </div>

      <div className="col-6">
        <div className="form-row">
          <label htmlFor="width">Line Height</label>
          <input
            type="text"
            id="width"
            value={_lineHeight}
            onChange={(ev) => { setLineHeight(ev.target.value) }}
            onBlur={updateLayer}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="form-row">
          <label htmlFor="font-family">Font Family</label>
          <select
            id="font-family"
            value={_fontFamily}
            onChange={(ev) => { setFontFamily(ev.target.value) }}
          >
            {doc.fonts.map(font => {
              return <option key={font.id} value={font.id}>{font.label}</option>
            })}
          </select>
        </div>
      </div>

      <div className="col-12">
        <div className="form-row">
          <label>Font Colour</label>
          <ColorPicker
            color={ _color }
            colors={ doc.brandColors }
            onChangeComplete={ setFontColor }
          />
        </div>
      </div>

      { _editorType === 'static' || _editorType === 'basic' ? <>

  			<div className="col-12 type-toggles">
          <ToggleButton
            value={_textAlign}
            onValue={'left'}
            onUpdate={() => { setTextAlign('left')}}
            disableToggle={true}
            iconOn=<i className="icon-align-left"></i>
            iconOff=<i className="icon-align-left"></i>
          />

          <ToggleButton
            value={_textAlign}
            onValue={'center'}
            onUpdate={() => { setTextAlign('center')}}
            disableToggle={true}
            iconOn=<i className="icon-align-center"></i>
            iconOff=<i className="icon-align-center"></i>
          />

          <ToggleButton
            value={_textAlign}
            onValue={'right'}
            onUpdate={() => { setTextAlign('right')}}
            disableToggle={true}
            iconOn=<i className="icon-align-right"></i>
            iconOff=<i className="icon-align-right"></i>
          />

          <ToggleButton
            value={_textAlign}
            onValue={'justify'}
            onUpdate={() => { setTextAlign('justify')}}
            disableToggle={true}
            iconOn=<i className="icon-align-justify"></i>
            iconOff=<i className="icon-align-justify"></i>
          />

          <ToggleButton
            value={_isBold}
            onUpdate={setIsBold}
            iconOn=<i className="icon-bold"></i>
            iconOff=<i className="icon-bold"></i>
          />

          <ToggleButton
            value={_isItalic}
            onUpdate={setIsItalic}
            iconOn=<i className="icon-italic"></i>
            iconOff=<i className="icon-italic"></i>
          />

          <ToggleButton
            value={_isUnderlined}
            onUpdate={setIsUnderlined}
            iconOn=<i className="icon-underline"></i>
            iconOff=<i className="icon-underline"></i>
          />
        </div>

        <div className="col-12">
          <div className="form-row">
            { _isSingleLine && <input
              type="text"
              id="text"
              value={_text}
              rows={10}
              onChange={ (ev) => { setText(ev.target.value) }}
              onBlur={updateLayer}
            /> }
            { !_isSingleLine && <textarea
              id="text"
              value={_text}
              rows={10}
              onChange={ (ev) => { setText(ev.target.value) }}
              onBlur={updateLayer}
            /> }
          </div>
        </div>
      </> : null }

      { _editorType === 'wysiwyg' && <>
        <div className="col-12">
          <div className="form-row">
            <Editor
              document={_editorState}
              onChange={handleMessageChange}
            />
          </div>
        </div>
      </> }

			<div className="col-12">
				<div className="form-row">
					<label htmlFor="placeholder">Placeholder Text</label>
					<textarea
						type="text"
						id="placeholder"
						value={_placeholder}
            rows={4}
						onChange={ (ev) => { setPlaceholder(ev.target.value) }}
						onBlur={updateLayer}
					/>
				</div>
			</div>

		</div>
	</div>;

};

export default SettingsImage;

import { useState, useEffect, useContext } from "react";
import { Tooltip } from 'react-tooltip';
import mainContext from '../../context/context.js';

const SettingsImage = ({layer, doc}) => {

  const { onUpdateSelected } = useContext(mainContext);

	const [_src, setSrc] = useState(layer.IMAGE.src);
	const [_size, setSize] = useState(layer.IMAGE.size);
	const [_position, setPosition] = useState(layer.IMAGE.position);

  const [_gallery, setGallery] = useState(layer.IMAGE.gallery);

  // listen for changes
  useEffect(() => {
		setSrc(layer.IMAGE.src);
  	setSize(layer.IMAGE.size);
  	setPosition(layer.IMAGE.position);
  	setGallery(layer.IMAGE.gallery);
  }, [layer]);

  // on blur update layers object
	const updateLayer = () => {
		let updated = {...layer};

		updated.IMAGE.src = _src;
		updated.IMAGE.size = _size;
		updated.IMAGE.position = _position;

		updated.IMAGE.gallery = _gallery;

		onUpdateSelected(updated);
	}

  useEffect(() => {
		updateLayer();
  }, [_position, _size, _src, _gallery]);

  const handleThumbClick = (url) => {
    setSrc(url);
  }

	return <div className="content">
		<div className="flex flex--wrap">

      <div className="col-12">
      </div>

			<div className="col-12">
				<div className="form-row">
					<label htmlFor="src">Image Source</label>
					<input
						type="text"
						id="src"
						value={_src}
						onChange={ (ev) => { setSrc(ev.target.value) }}
						onBlur={updateLayer}
					/>
				</div>
			</div>

			<div className="col-6">
				<div className="form-row">
					<label htmlFor="size">Size</label>
					<select
						id="size"
						value={_size}
						onChange={ (ev) => { setSize(ev.target.value); }}
					>
						<option value="">Full</option>
						<option value="cover">Cover</option>
						<option value="contain">Contain</option>
					</select>
				</div>
			</div>

			<div className="col-6">
				<div className="form-row">
					<label htmlFor="size">Position</label>
					<select
						id="size"
						value={_position}
						onChange={ (ev) => { setPosition(ev.target.value); }}
					>
						<option value="center">Center</option>
						<option value="top">Top</option>
						<option value="bottom">Bottom</option>
						<option value="left">Left</option>
						<option value="right">Right</option>
					</select>
				</div>
			</div>

      <div className="col-12">
        <div className="form-row">
          <label htmlFor="l-gallery">Gallery</label>
          <select
            id="l-gallery"
            value={_gallery}
            onChange={(ev) => { setGallery(ev.target.value) }}
          >
            <option  value="">- Choose an option -</option>
            {doc.galleries.map(gallery => {
              return <option key={gallery.id} value={gallery.id}>{gallery.name}</option>
            })}
          </select>
        </div>
        {_gallery !== '' && <div className="gallery-tiles">
          {doc.galleries.filter(g => g.id === _gallery)[0].images.map((image, index) => {
            return <div key={image.id} className="gallery-tile">
              <button
                id={image.id}
                className="gallery-tile__inner"
                style={{background:'url('+image.src+') no-repeat center'}}
                onClick={() => {handleThumbClick(image.src)}}
              ></button>
						  <Tooltip anchorId={image.id} content={image.title} place="top" />
            </div>
          })}
        </div>}
      </div>

      <div className="col-12">
      </div>

      <div className="col-12">
      </div>

      <div className="col-12">
      </div>

      <div className="col-12">
      </div>

		</div>
	</div>;

};

export default SettingsImage;

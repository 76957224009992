import { useState, useEffect, useContext } from "react";
import mainContext from '../../context/context.js';
import TabbedPages from '../general/TabbedPages.js';
import TabbedPage from '../general/TabbedPage.js';
import CSSTextarea from '../general/CSSTextarea.js';
import ColorPicker from '../form/ColorPicker.js';

const SettingsLayer = ({layer, doc}) => {

  const { onUpdateSelected } = useContext(mainContext);

	const [_x, setX] = useState(layer.x);
	const [_y, setY] = useState(layer.y);
	const [_width, setWidth] = useState(layer.width);
	const [_height, setHeight] = useState(layer.height);

  const [_title, setTitle] = useState(layer.title);
  const [_tooltip, setTooltip] = useState(layer.tooltip);

  const [_isEditable, setIsEditable] = useState(layer.editable);

  const [_bgColour, setBGColor] = useState(layer.background);
  const [_bgOpacity, setBGOpacity] = useState(layer.bgOpacity);
  const [_borderColor, setBorderColor] = useState(layer.borderColor);
  const [_borderWidth, setBorderSize] = useState(layer.borderWidth);
  const [_borderRadius, setBorderRadius] = useState(layer.borderRadius);

  const [_padding, setPadding] = useState(layer.padding);

  const [_customStyles, setCustomStyles] = useState(layer.customStyles);

  // listen for changes
  useEffect(() => {
		setX(layer.x);
		setY(layer.y);
		setWidth(layer.width);
		setHeight(layer.height);
		setPadding(layer.padding);

		setCustomStyles(layer.customStyles);

		setTitle(layer.title);
  	setTooltip(layer.tooltip);

		setIsEditable(layer.editable);

		setBGColor(layer.background);
    setBGOpacity(layer.bgOpacity);
		setBorderColor(layer.borderColor);
		setBorderSize(layer.borderWidth);
		setBorderRadius(layer.borderRadius);
  }, [layer]);

  // on blur update layers object
	const updateLayer = () => {
		let updated = {...layer};

		updated.title = _title;
    updated.tooltip = _tooltip;

    updated.editable = _isEditable;

		updated.x = parseInt(_x);
		updated.y = parseInt(_y);
		updated.width = parseInt(_width);
		updated.height = parseInt(_height);
		updated.padding = _padding;

		updated.customStyles = _customStyles;

    updated.background = _bgColour;
    updated.bgOpacity = _bgOpacity;
		updated.borderColor = _borderColor;
		updated.borderWidth = parseInt(_borderWidth);
		updated.borderRadius = _borderRadius;

		onUpdateSelected(updated);
	}

  // listen for elements onChange
  useEffect(() => {
    updateLayer();
  }, [_bgColour, _borderColor, _customStyles]);

  let tabs

	return <div className="content">

    <TabbedPages tabs={[
      { label: 'Layer', id: 'layer' },
      { label: 'Stylesheet (CSS)', id: 'stylesheet' },
      { label: 'Group', id: 'group' }
    ]}>

      <TabbedPage key='layer' tab='layer'>
        <div className="flex flex--wrap">
          { layer.type !== 'SHAPE' &&
            <div className="col-12">
              <div className="form-row">
                <label>Editable</label>
                <label htmlFor="editable" className="label-inline">
                <input
                  type="checkbox"
                  id="editable"
                  checked={_isEditable}
                  onChange={ (ev) => { setIsEditable(ev.target.checked) }}
                  onBlur={updateLayer}
                /> <span>If unchecked, the user will not be able to edit this field.</span></label>
              </div>
            </div>
          }
          <div className="col-12">
            <div className="form-row">
              <label htmlFor="l-layername">Layer Name</label>
              <input
                type="text"
                id="l-layername"
                value={_title}
                onChange={ (ev) => { setTitle(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="form-row">
              <label htmlFor="l-tooltip">Tooltip</label>
              <input
                type="text"
                id="l-tooltip"
                value={_tooltip}
                onChange={ (ev) => { setTooltip(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="l-width">Width</label>
              <input
                type="number"
                id="l-width"
                min={0}
                step={1}
                value={_width}
                onChange={ (ev) => { setWidth(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="l-height">Height</label>
              <input
                type="number"
                id="l-height"
                min={0}
                step={1}
                id="title"
                value={_height}
                onChange={ (ev) => { setHeight(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="l-x">x</label>
              <input
                type="number"
                id="l-x"
                min={0}
                step={1}
                value={_x}
                onChange={ (ev) => { setX(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="l-y">y</label>
              <input
                type="number"
                id="l-y"
                min={0}
                step={1}
                value={_y}
                onChange={ (ev) => { setY(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label>Background</label>
							<ColorPicker
								color={ _bgColour }
                colors={ doc.brandColors }
								onChangeComplete={ setBGColor }
							/>
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="l-bg-opacity">BG Opacity</label>
              <input
                type="number"
                id="l-bg-opacity"
                min={0}
                max={1}
                step={0.1}
                value={_bgOpacity}
                onChange={ (ev) => { setBGOpacity(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          <div className="col-6"></div>
          <div className="col-3">
            <div className="form-row">
              <label>Border</label>
							<ColorPicker
								color={ _borderColor }
                colors={ doc.brandColors }
								onChangeComplete={ setBorderColor }
							/>
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="l-border-size">Size (px)</label>
              <input
                type="number"
                id="l-border-size"
                min={0}
                step={1}
                value={_y}
                value={_borderWidth}
                onChange={ (ev) => { setBorderSize(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="form-row">
              <label htmlFor="l-border-radius">Radius</label>
              <input
                type="text"
                id="l-border-radius"
                value={_borderRadius}
                onChange={ (ev) => { setBorderRadius(ev.target.value) }}
                onBlur={updateLayer}
              />
            </div>
          </div>
          { layer.type !== 'SHAPE' &&
            <div className="col-3">
              <div className="form-row">
                <label htmlFor="l-padding">Padding</label>
                <input
                  type="text"
                  id="l-padding"
                  value={_padding}
                  onChange={ (ev) => { setPadding(ev.target.value) }}
                  onBlur={updateLayer}
                />
              </div>
            </div>
          }
          <div className="col-12">
            <div className="form-row">
              <label htmlFor="title">Element ID</label>
              <div className="input-readonly">#{layer.id}</div>
            </div>
          </div>
        </div>
      </TabbedPage>

      <TabbedPage key='stylesheet' tab='stylesheet'>
        <CSSTextarea
          styles={_customStyles}
          onUpdate={setCustomStyles}
        />
      </TabbedPage>

      <TabbedPage key='group' tab='group'>

      </TabbedPage>

    </TabbedPages>


	</div>;
};

export default SettingsLayer;

import { useState, useEffect } from "react";

import Layers from './Layers';

import SettingsPanel from './settings/SettingsPanel';
import SettingsDocument from './settings/SettingsDocument';
import SettingsLayer from './settings/SettingsLayer';
import SettingsText from './settings/SettingsText';
import SettingsImage from './settings/SettingsImage';
import SettingsGroup from './settings/SettingsGroup';

const Settings = ({
	selectedObjects = [],
	currentGroup,
	hoverObject,
	layers,
	doc,
	snapToGrid,
	gridSize,
	onUpdateDocument
}) => {

	const [_selectedLayer, setSelectedLayer] = useState(null);

	const [_isOpenDoc, setIsOpenDoc] = useState(true);
	const [_isOpenLayers, setIsOpenLayers] = useState(true);
	const [_isOpenLayerSettings, setIsOpenLayerSettings] = useState(true);
	const [_isOpenImage, setIsOpenImage] = useState(true);
	const [_isOpenType, setIsOpenType] = useState(true);
	const [_isOpenGroup, setIsOpenGroup] = useState(true);
	const [_isOpenShape, setIsOpenShape] = useState(true);
	//const [_isOpen, setIsOpen] = useState(true);

  useEffect(() => {
		if (selectedObjects.length === 1) {
			let layer = layers.getByID(selectedObjects[0]);
			//console.log('selec', layer);
			setSelectedLayer({...layer});
		}
		else {
			setSelectedLayer(null);
		}
  }, [layers, selectedObjects]);

	return <div className="settings">
		<div className="settings__inner">
			<div className="settings__panels">

				<SettingsPanel
					title="Layers"
					isOpen={_isOpenLayers}
					onUpdateOpen={setIsOpenLayers}
				>
					<Layers
						layers={layers}
						selectedObjects={selectedObjects}
		        currentGroup={currentGroup}
						hoverObject={hoverObject}
						/>
				</SettingsPanel>

				{ _selectedLayer &&
					<>
						<SettingsPanel
							title="Layer Settings"
							isOpen={_isOpenLayerSettings}
							onUpdateOpen={setIsOpenLayerSettings}
						>
							<SettingsLayer layer={_selectedLayer} doc={doc} />
						</SettingsPanel>
						{
							_selectedLayer.type === 'IMAGE' &&
							<SettingsPanel
								title=<span><i className="icon-image"></i> Image</span>
								isOpen={_isOpenImage}
								onUpdateOpen={setIsOpenImage}
							>
								<SettingsImage layer={_selectedLayer} doc={doc} />
							</SettingsPanel>
						}
						{
							_selectedLayer.type === 'TEXT' &&
							<SettingsPanel
								title=<span><i className="icon-font"></i> Type</span>
								isOpen={_isOpenType}
								onUpdateOpen={setIsOpenType}
							>
								<SettingsText layer={_selectedLayer} doc={doc} />
							</SettingsPanel>
						}
						{
							_selectedLayer.type === 'GROUP' &&
							<SettingsPanel
								title=<span><i className="icon-draw-square"></i> Group</span>
								isOpen={_isOpenGroup}
								onUpdateOpen={setIsOpenGroup}
							>
								<SettingsGroup layer={_selectedLayer} doc={doc} />
							</SettingsPanel>
						}
					</>
				}

				{ selectedObjects.length === 0 &&
					<SettingsPanel
						title="Document Settings"
						isOpen={_isOpenDoc}
						onUpdateOpen={setIsOpenDoc}
					>
						<SettingsDocument
							doc={doc}
							onUpdateDocument={onUpdateDocument}
							snapToGrid={snapToGrid}
							gridSize={gridSize}
						/>
					</SettingsPanel>
				}

				{ /*
				<SettingsPanel title="Appearance">
					Content here
				</SettingsPanel>
				*/ }
			</div>

		</div>
	</div>;
};

export default Settings;

import { useState, useEffect, useRef } from "react";
import { getLayerObject } from '../LayerConfig.js';
import { capitalise } from '../Utils.js';
import { LayerData } from '../classes/LayerData.js';
import PageHeader from './PageHeader';
import Tools from './Tools';
import Settings from './Settings';
import Artboard from './Artboard';

import { useSave, useGetPreview } from '../clients/ActionsClient.js';

import mainContext from '../context/context.js';

const Stage = () => {

  const artboardRef = useRef({});

  // Init Artboard / Document
  // TODO - import from PHP
  const [_document, setDocument] = useState({
    id: 'design3',
    clientID: 414,
    title: 'Latest Ad',
    width: 620,
    height: 877,
    doctype: 'Job Adverts (Letterbox)',
    doctypeSlug: 'job_adverts_(letterbox)',
    baseFont: 'open-sans-body',
    baseFontSize: 16,
    backgroundColor: '#fff',
    customStyles:'#image_skull { \nbackground-color: #f00 !important; \n}',
    fonts: [
      {
        id:'open-sans-body',
        label:'Open Sans (Body)',
        family:'Open Sans',
        serif:false,
        weight:300
      },
      {
        id:'open-sans-header',
        label:'Open Sans (Header)',
        family:'Open Sans',
        serif:false,
        weight:600
      },
      {
        id:'raleway',
        label:'Raleway',
        family:'Raleway',
        serif:false,
        weight:400
      },
      {
        id:'roboto',
        label:'Roboto',
        family:'Roboto',
        serif:false,
        weight:500
      },
      {
        id:'vollkorn',
        label:'Vollkorn',
        family:'Vollkorn',
        serif:true,
        weight:800
      }
    ],
    galleries: [
      {
        id:'g-gifs',
        name:'Gifs',
        images: [
          {id:'img5',src:"https://helpx.adobe.com/content/dam/help/en/photoshop/how-to/create-animated-gif/jcr_content/main-pars/image_4389415/create-animated-gif_3a-v2.gif",title:"Skull"}
        ]
      },
      {
        id:'g2',
        name:'GP',
        images: [
          {id:'img6',src:"https://goldpebble.co.uk/wp-content/uploads/2022/11/7594915544749063101-576x1024.jpg",title:"Boat"},
          {id:'img7',src:"https://goldpebble.co.uk/wp-content/uploads/2022/11/11012325975917443417.jpg",title:"Red Arrows"},
          {id:'img8',src:"https://goldpebble.co.uk/wp-content/uploads/2022/10/311322247_1548857355545839_8895861229535267933_n-1024x683.jpeg",title:"GP HQ"},
          {id:'img9',src:"https://goldpebble.co.uk/wp-content/uploads/2022/05/me-1024x834.jpg",title:"Taran"},
          {id:'img10',src:"https://goldpebble.co.uk/wp-content/uploads/2022/05/DeanProfilePic.jpeg",title:"Dean"},
          {id:'img11',src:"https://goldpebble.co.uk/wp-content/uploads/2022/05/Paul-Goldsmith.jpg",title:"Paul"},
        ]
      },
      {
        id:'g-unsplash',
        name:'Unsplash',
        images: [
          {id:'img12',src:"https://images.unsplash.com/photo-1552733407-5d5c46c3bb3b?crop=entropy&cs=srgb&fm=jpg&ixid=Mnw4ODkxMXwwfDF8c2VhcmNofDd8fHRyYXZlbHxlbnwwfHx8fDE2MTUyODc3NDk&ixlib=rb-1.2.1&q=85&w=1000",title:"Woodland"},
        ]
      }
    ],
    brandColors:[ '#ffffff', '#335C67', '#FFF3B0', '#E09F3E', '#9E2A2B', '#540B0E' ]
  });

  // Init Layers - Load from saved
  // TODO - import from PHP
  const initialLayers = [
    getLayerObject({
      title: 'Background',
      x: 0,
      y: 0,
      width: 500,
      height: 400,
      background: '#000',
      bgOpacity: 0,
      tooltip: 'Add a background image',
      editable: false,
      IMAGE: {
        gallery:'g-unsplash',
        src: 'https://images.unsplash.com/photo-1552733407-5d5c46c3bb3b?crop=entropy&cs=srgb&fm=jpg&ixid=Mnw4ODkxMXwwfDF8c2VhcmNofDd8fHRyYXZlbHxlbnwwfHx8fDE2MTUyODc3NDk&ixlib=rb-1.2.1&q=85&w=1000',
        isBackground: true
      },
    },'IMAGE'),
    getLayerObject({
      title: 'Text layer',
      x: 150,
      y: 150,
      width: 200,
      height: 100,
      background: '#999',
      bgOpacity: 0.5,
      TEXT: {
        editorType: 'wysiwyg',
        value: 'This is some text! This is some more text!',
        color: '#fff',
        fontFamily: 'Roboto',
        fontWeight: 700,
      },
    },'TEXT'),
    getLayerObject({
      id: 'image_skull',
      title: 'Skull image',
      x: 50,
      y: 50,
      width: 200,
      height: 150,
      background: '#000',
      bgOpacity: 0,
      customStyles: 'transform: scaleX(-1);',
      IMAGE: {
        gallery:'g-gifs',
        src: 'https://helpx.adobe.com/content/dam/help/en/photoshop/how-to/create-animated-gif/jcr_content/main-pars/image_4389415/create-animated-gif_3a-v2.gif',
        size: 'contain'
      },
    },'IMAGE')
  ];

	const [_layers, setLayers] = useState(new LayerData());
  const [_visibleObjects, setVisibleObjects] = useState([]);
	const [_mode, setMode] = useState('SELECT');
	const [_selectedObjects, setSelectedObjects] = useState([]);
  const [_selectedBoundary, setSelectedBoundry] = useState(null);
	const [_hoverObject, setHoverObject] = useState(null);

	const [_shiftDown, setShiftDown] = useState(false);
  const [_cmdDown, setCmdDown] = useState(false);

	const [_isDragging, setIsDragging] = useState(false);
	const [_startPos, setStartPos] = useState({x:0,y:0});
	const [_dragOffset, setDragOffset] = useState({x:0,y:0});
  const [_resizeDirection, setResizeDirection] = useState(null);
  const [_currentGroup, setCurrentGroup] = useState(null);

	const [_snapToGrid, setSnapToGrid] = useState(true);
	const [_gridSize, setGridSize] = useState({h:10, v:10});

	const [_doSave, setDoSave] = useState(false);
	const [_doPreview, setDoPreview] = useState(false);

  const saveResponse = useSave(_document,_layers,_doSave);
  const previewPriceResponse = useGetPreview(_document,_layers,_doPreview);

  /* Vars */

  // general vars
  const artboardPos = {x:80,y:108};


  /* Init */

  // init layer object
  useEffect(() => {
    let data = new LayerData();
    data.init(window.layers || initialLayers);
    setLayers(data);
  }, []);


  /* Events */

  // keyboard events
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
	  document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
  	  document.removeEventListener('keyup', handleKeyUp);
    }
  }, [_cmdDown, _shiftDown]);

  // mouse/touch events
  useEffect(() => {

    if (artboardRef && artboardRef.current) {
      // TODO - move these to artboard inner
  	  artboardRef.current.addEventListener('mousedown', startDrag);
  	  //document.addEventListener('touchstart', startDrag);
  	  artboardRef.current.addEventListener('mouseup', endDrag);
  	  //document.addEventListener('touchend', endDrag);
  	  artboardRef.current.addEventListener('mousemove', handleDrag);
  	  //document.addEventListener('touchmove', handleDrag);

      return () => {
        // TODO - move these to artboard inner
    	  artboardRef.current.removeEventListener('mousedown', startDrag);
    	  //document.removeEventListener('touchstart', startDrag);
    	  artboardRef.current.removeEventListener('mouseup', endDrag);
    	  //document.removeEventListener('touchend', endDrag);
    	  artboardRef.current.removeEventListener('mousemove', handleDrag);
    	  //document.removeEventListener('touchmove', handleDrag);
      }
    }

  }, [_selectedObjects, _isDragging, _startPos, _dragOffset, _resizeDirection]);

  // update selection boundry / resize tools if vars change
  useEffect(() => {
    calculateSelectedBoundary();
  }, [_selectedObjects, _dragOffset]);

  useEffect(() => {
    setVisibleObjects(_layers.getAll());
  }, [_layers]);

  useEffect(() => {
    if (!_isDragging) {
      setStartPos({x:0,y:0});
      setDragOffset({x:0,y:0});
    }
  }, [_isDragging]);

  /* API handlers */


  // Save doc
  const handleSave = () => {
    console.log('handleSave');
    setDoSave(true);
  }
  useEffect(() => {
    console.log('saveResponse',saveResponse);

    if (saveResponse.success || saveResponse.error) {
      setDoSave(false);
    }
  }, [saveResponse]);

  // Preview doc
  const handlePreview = () => {
    console.log('handlePreview');
    setDoPreview(true);
  }
  useEffect(() => {
    console.log('previewPriceResponse',previewPriceResponse);

    if (previewPriceResponse.success || previewPriceResponse.error) {
      setDoSave(false);
    }
  }, [previewPriceResponse]);


	/* Layers */

  // clone Layer data so react knows it has changed - otherwise no redraw
  const cloneLayers = () => {
    return Object.assign(Object.create(Object.getPrototypeOf(_layers)), _layers);
  }

  // add new layer
	const addNewObject = (x, y) => {
		if (_mode === 'SELECT') {
			return;
		}


    if (_currentGroup && _currentGroup !== _selectedObjects.includes(_currentGroup)) {

      const l = _layers.getByID(_currentGroup);
      x -= l.x;
      y -= l.y;
    }

    if (_snapToGrid) {
      x = Math.round(x / _gridSize.h) * _gridSize.h;
      y = Math.round(y / _gridSize.v) * _gridSize.v;
    }

		const layer = {
			type: _mode,
			title: capitalise(_mode) + ' layer',
			x: x,
			y: y,
			width: 0,
			height: 0,
		};

    if (_mode === 'TEXT') {

    }
    else if (_mode === 'IMAGE') {
      layer.img = {
				src: '',
				position: 'center',
				size: 'contain'
			};
    }

		const layers = cloneLayers();

    let id;
    if (_currentGroup && _mode !== 'GROUP') {
      id = layers.addChildLayer(_mode, layer, _currentGroup, true);
    }
    else {
      id = layers.addLayer(_mode, layer, true);
    }

    if (id) {
      setLayers(layers);

      if (_mode === 'GROUP') {
        setCurrentGroup(id);
      }
    }

    return id;
	}

  // change layer position
	const updateObjectZindex = (direction, id) => {

    const layer = _layers.getByID(id);
    let items = [];

    if (layer.parentLayer) {
      items = _layers.getChildren(layer.parentLayer);
    }
    else {
      items = _layers.getAll();
    }

    const numItems = items.length;
    const index = items.findIndex(item => item.id === id);
    let canSwap = false,to,from = _layers.levelForLayer(id);

    // move down
    if (index > 0 && direction < 0) {
      to = _layers.levelForLayer(items[(index-1)].id);
      canSwap = true;
    }

    // move up
    if (index < numItems - 1 && direction > 0) {
      to = _layers.levelForLayer(items[(index+1)].id);
      canSwap = true;
    }

    if (canSwap) {
      _layers.swap(from, to, true);
      setLayers(cloneLayers());
    }

	}

  // update layer object
	const updateObject = (layer) => {
    let layers = cloneLayers();
    layers.updateLayerByID(layer, true);
    setLayers(layers);
    calculateSelectedBoundary();
	}

  // update selection on drag/resize
	const updateLayerPositions = (updateHistory) => {
    let layers = cloneLayers();

		_selectedObjects.forEach((id, i) => {
			// get layer
			let layer = layers.getByID(id);

			if (layer) {

        // resizing
        if (_resizeDirection) {
          switch(_resizeDirection) {
            case 'n':
              calcN(layer);
              break;
            case 's':
              calcS(layer);
              break;
            case 'w':
              calcW(layer);
              break;
            case 'e':
              calcE(layer);
              break;
            case 'nw':
              calcN(layer);
              calcW(layer);
              break;
            case 'ne':
              calcN(layer);
              calcE(layer);
              break;
            case 'sw':
              calcS(layer);
              calcW(layer);
              break;
            case 'se':
              calcS(layer);
              calcE(layer);
              break;
          }
        }
        // moving
        else {

          // if snap to grid - adjust to grid size
          if (_snapToGrid) {
            layer.x = Math.round(layer.x / _gridSize.h) * _gridSize.h;
      			layer.y = Math.round(layer.y / _gridSize.v) * _gridSize.v;

            layer.width = Math.round(layer.width / _gridSize.h) * _gridSize.h;
      			layer.height = Math.round(layer.height / _gridSize.v) * _gridSize.v;
          }

          layer.x += _dragOffset.x;
  				layer.y += _dragOffset.y;
        }
			}

      layers.updateLayerByID(layer, id, updateHistory);

		});

		setLayers(layers);
	}

	const removeSelectedLayers = () => {
    if (_selectedObjects.length === 0) {
      return;
    }
    let layers = cloneLayers();
    layers.removeLayersByID(_selectedObjects);
    setLayers(layers);

    setSelectedObjects([]);
	}

	const duplicateSelectedLayers = () => {
    let layers = cloneLayers();
    layers.duplicateLayersByID(_selectedObjects);
    setLayers(layers);

    setSelectedObjects([]);
	}

  const canGroupSelected = () => {
    if (_selectedObjects.length < 2) {
      return;
    }

    let canGroup = true;

    _selectedObjects.forEach((id, i) => {
      let layer = _layers.getByID(id);
      if (layer.type === 'GROUP') {
        canGroup = false;
      }
    });

    return canGroup;
  }

  const groupSelected = () => {
    if (_selectedObjects.length < 2 || !_selectedBoundary || !canGroupSelected()) {
      return;
    }

    const layers = cloneLayers();

    // layer params
    const layer = {
			type: 'GROUP',
			title: 'Group layer',
			x: _selectedBoundary.x,
			y: _selectedBoundary.y,
			width: _selectedBoundary.width,
			height: _selectedBoundary.height,
		};

    // create new group
    const id = layers.addLayer('GROUP', layer, false);

    // update selected layers positions and assign parent
    _selectedObjects.forEach((item, i) => {
      let sLayer = layers.getByID(item);

      sLayer.parentLayer = id;
      sLayer.x -= layer.x;
      sLayer.y -= layer.y;
    });

    if (!id) {
      return;
    }

    // add undo point
    layers.pushHistory();

    // update layers
    setLayers(layers);

    // start group edit mode
    setCurrentGroup(id);
  }

  const ungroupSelected = () => {

    // check only one selected
    if (_selectedObjects.length !== 1 && !_currentGroup) {
      return;
    }

    const id = (_currentGroup) ? _currentGroup : _selectedObjects[0];

    // check if selected one is group
    const layer = _layers.getByID(id);
    if (layer.type !== 'GROUP') {
      return;
    }

    const children = _layers.getChildren(id);
    if (children.length) {
      children.forEach((item, i) => {
        item.parentLayer = null;
        item.x += layer.x;
        item.y += layer.y;
        _layers.updateLayerByID(item, false);
      });
    }

    _layers.removeLayersByID([layer.id]);

    setLayers(cloneLayers());

    // exit group edit mode
    if (_currentGroup) {
      setCurrentGroup(null);
    }

  }

	/* Modes */
	const handleUpdateMode = (mode) => {
		setMode(mode);
    setSelectedObjects([]);
	}

	const enterGroupEdit = (id) => {
    setCurrentGroup(id);
  }

	const exitGroupEdit = () => {
    setCurrentGroup(null);
  }

	/* Events */

  // on update document
	const handleUpdateDocument = ({width, height, title}) => {

		let doc = _document;

		if (width !== undefined) {
			doc.width = parseInt(width);
		}
		if (height !== undefined) {
			doc.height = parseInt(height);
		}
		if (title !== undefined) {
			doc.title = title;
		}

		setDocument({...doc});
	}

  /* Selected Objects */

  const handleObjectSelect = (id) => {

    // ignore if not in select mode
    if (_mode !== 'SELECT') {
      return;
    }

    let ids = [];
    if (!_selectedObjects.includes(id)) {
      // if holding shift
      if (_shiftDown) {
        ids = [id, ..._selectedObjects];
        setSelectedObjects(ids);
      }
      else {
        // check if in group edit - if so exists
        const l = _layers.getByID(id);
        if (_currentGroup) {
          if (l.id !== _currentGroup && l.parentLayer !== _currentGroup) {
            exitGroupEdit();
          }
        }
        else {
          if (l.parentLayer) {
            enterGroupEdit(l.parentLayer);
          }
        }

        ids = [id];
        setSelectedObjects(ids);
      }
    }
    else {
      if (_shiftDown) {
        ids = _selectedObjects.filter(i => id !== i);
        setSelectedObjects(ids);
      }
    }
    setHoverObject(null);
    // console.log('b1', _selectedObjects);
    // calculateSelectedBoundary({ids:ids});
  }

  // bounding box resize calculations
  const calcN = (b) => {
    const orgHeight = b.height;
    b.y = (b.height - _dragOffset.y >= 0) ? b.y + _dragOffset.y : b.y + orgHeight;
    b.height -= _dragOffset.y;
    b.height = Math.max(b.height, 0);
  }
  const calcS = (b) => {
    b.height += _dragOffset.y;
    b.height = Math.max(b.height, 0);
  }
  const calcW = (b) => {
    const orgWidth = b.width;
    b.x = (b.width - _dragOffset.x >= 0) ? b.x + _dragOffset.x : b.x + orgWidth;
    b.width -= _dragOffset.x;
    b.width = Math.max(b.width, 0);
  }
  const calcE = (b) => {
    b.width += _dragOffset.x;
    b.width = Math.max(b.width, 0);
  }

  const calculateSelectedBoundary = () => {

    const ids = _selectedObjects;

    if (ids.length === 0) {
      setSelectedBoundry(null);
      return;
    }

    let boundry = {
      x: null,
      y: null,
      x2: null,
      y2: null,
      width: null,
      height: null
    }

    // find outer points for all selected objects
    let found = false;
    ids.forEach((id, i) => {
      let layer = _layers.getByID(id);
      if (layer) {
        const relPos = _layers.getRelativePosition(id);

        boundry.x = boundry.x !== null ? Math.min(boundry.x, relPos.x) : relPos.x;
        boundry.y = boundry.y !== null ? Math.min(boundry.y, relPos.y) : relPos.y;

        boundry.x2 = boundry.x2 !== null ? Math.max(boundry.x2, relPos.x + layer.width) : relPos.x + layer.width;
        boundry.y2 = boundry.y2 !== null ? Math.max(boundry.y2, relPos.y + layer.height) : relPos.y + layer.height;

        found = true;
      }
    });

    if (!found) {
      setSelectedBoundry(null);
      return;
    }

    // Calc width and height
    boundry.width = boundry.x2 - boundry.x;
    boundry.height = boundry.y2 - boundry.y;

    // adjust if dragging
    if (_isDragging) {
      // resizing
      if (_resizeDirection) {
        switch(_resizeDirection) {
          case 'n':
            calcN(boundry);
            break;
          case 's':
            calcS(boundry);
            break;
          case 'w':
            calcW(boundry);
            break;
          case 'e':
            calcE(boundry);
            break;
          case 'nw':
            calcN(boundry);
            calcW(boundry);
            break;
          case 'ne':
            calcN(boundry);
            calcE(boundry);
            break;
          case 'sw':
            calcS(boundry);
            calcW(boundry);
            break;
          case 'se':
            calcS(boundry);
            calcE(boundry);
            break;
        }
      }
      // moving
      else {
        boundry.x += _dragOffset.x;
        boundry.y += _dragOffset.y;
      }
    }

    setSelectedBoundry(boundry);

    return boundry;
  }

	const handleStageDoubleClick = (ev) => {

    if (ev.target.className.includes('object--group')) {

  		const id = ev.target.dataset.id;
      setCurrentGroup(id);
    }

    if (ev.target.className === 'stage__inner') {
      exitGroupEdit();
    }
  }

	// clear selected object if clicking on the stage
	const handleStageClick = (ev) => {

 		if (ev.target.className !== 'stage__inner') {

			// Add new object if in right mode
			if (_selectedObjects.length === 0 && ( _mode == 'TEXT' || _mode == 'IMAGE' || _mode == 'SHAPE' || _mode == 'GROUP' )) {

        // add object
        const x = ev.pageX - artboardPos.x;
        const y = ev.pageY - artboardPos.y;
				const id = addNewObject(x, y);

        // set resize direction
        setResizeDirection('se');

        // select new object
        setSelectedObjects([id]);

        // start drag
        let pageX = ev.pageX;
        let pageY = ev.pageY;

        setStartPos({
    			x:pageX,
    			y:pageY
    		});

    		setIsDragging(true);
			}

		}

    // deselct objects if clicked off artboard
		else {
			setSelectedObjects([]);
			setHoverObject(null);
		}
	}

	const handleKeyDown = (ev) => {
    // console.log(ev.keyCode);

    // DELETE
    // if (ev.keyCode === 8) {
    //   // TODO - check not editing text
    //   removeSelectedLayers();
    // }

    // command a - select all
		if (_cmdDown && ev.keyCode === 65 ) {
      ev.preventDefault();
      setSelectedObjects(_layers.getAll().map(l => { return l.id; }));
    }

    // command + g - group
		if (!_shiftDown && _cmdDown && ev.keyCode === 71 ) {
      ev.preventDefault();
      groupSelected();
    }

    // command + g + shift - ungroup
		if (_shiftDown && _cmdDown && ev.keyCode === 71 ) {
      ev.preventDefault();
      ungroupSelected();
    }

    // command z - undo
    if (ev.keyCode === 91) {
      setCmdDown(true);
    }

    // command + z - undo
		if (!_shiftDown && _cmdDown && ev.keyCode === 90 ) {
      console.log('undo');

			setSelectedObjects([]);
			setHoverObject(null);

      _layers.historyBack();
      setLayers(cloneLayers());
	    updateLayerPositions(false);
    }

    // command + z + shift - redo
		if (_shiftDown && _cmdDown && ev.keyCode === 90 ) {
      console.log('redo!');

			setSelectedObjects([]);
			setHoverObject(null);

      _layers.historyForward();
      setLayers(cloneLayers());
	    updateLayerPositions(false);
    }

    // command + [ push back
		if (_cmdDown && ev.keyCode === 219 ) {
      ev.preventDefault();
      if (_selectedObjects.length === 1) {
        let id = _selectedObjects[0];
        updateObjectZindex(-1, id);
      }
    }

    // command + [ push forward
		if (_cmdDown && ev.keyCode === 221 ) {
      ev.preventDefault();
      if (_selectedObjects.length === 1) {
        let id = _selectedObjects[0];
        updateObjectZindex(1, id);
      }
    }

		// add shift key
		if (ev.keyCode === 16) {
			setShiftDown(true);
		}
	}
	const handleKeyUp = (ev) => {
		// remove shift key
		if (ev.keyCode === 16) {
			setShiftDown(false);
		}
    // command / apple key
    if (ev.keyCode === 91) {
      setCmdDown(false);
    }
	}

	// dragging
	const startDrag = (ev) => {

    // fudge after moving event to document
 		if (!ev || ev.target.className === 'settings') {
      return;
    }

    //console.log('startDrag', _selectedObjects, ev.target.dataset.id);

    handleStageClick(ev);

    //console.log('startDrag', _mode, _selectedObjects);
		if (!_selectedObjects.length || _mode !== 'SELECT') {
      console.log('r1');
			return;
		}

		// ignore if not current object
		const id = ev.target.dataset.id;
		if (!_selectedObjects.includes(id)) {
      console.log('r2');
			return;
		}

    if (_snapToGrid) {
      updateLayerPositions(false);
    }

    let pageX = ev.pageX;
    let pageY = ev.pageY;
		setStartPos({
			x:pageX,
			y:pageY
		});
		setIsDragging(true);
	}
	const handleDrag = (ev) => {

		if (!_selectedObjects.length || !_isDragging) {
			return;
		}

    let pageX = ev.pageX;
    let pageY = ev.pageY;

		let deltaX = pageX - _startPos.x;
		let deltaY = pageY - _startPos.y;

    if (_snapToGrid) {
      deltaX = Math.round(deltaX / _gridSize.h) * _gridSize.h;
      deltaY = Math.round(deltaY / _gridSize.v) * _gridSize.v;
    }

		setDragOffset({x:deltaX,y:deltaY});

    // update boundry
    // console.log('b2');
    // calculateSelectedBoundary({ids:_selectedObjects, offset:{x:deltaX,y:deltaY}});

	}
	const endDrag = (ev) => {
    console.log('end drag', _selectedObjects, _isDragging);
    setResizeDirection(null);
    setIsDragging(false);

    // if added new object - change mode back to select
    if (_mode !== 'SELECT' && (_dragOffset.x !== 0 ||  _dragOffset.y !== 0)) {
      setMode('SELECT');
    }

		if (_selectedObjects.length && _isDragging) {
      const hasMoved = _dragOffset.x !== 0 || _dragOffset.x !== 0;
			updateLayerPositions(hasMoved); // update layer history
		}
    //setResizeDirection(null);
	}

  // contect object - accessible to all child nodes of mainContext
  const wContext = {
    onSave: () => {
      handleSave();
    },
    onPreview: () => {
      handlePreview();
    },
    onObjectHover: (id) => {
			if (!id) {
				setHoverObject(id);
				return;
			}
			if (_selectedObjects.includes(id)
				|| (_selectedObjects.length && !_shiftDown)
			) {
				return;
			}
			setHoverObject(id);
    },
    onObjectSelect: (id, ev) => {
      handleObjectSelect(id);
      startDrag(ev);
    },
    onLayerDblClick: (id, type) => {
      if (type === 'GROUP') {
        enterGroupEdit(id);
      }
    },
    onDeleteSelected: () => {
      removeSelectedLayers();
    },
    onCloneSelected: () => {
      duplicateSelectedLayers();
    },
    onGroupSelected: () => {
      groupSelected();
    },
    onUpdateSelected: (layerData) => {
      updateObject(layerData);
    },
    fontForID: (fontID) => {
      return _document.fonts.filter(font => font.id === fontID)[0];
    },
    onStartResize: (direction,ev) => {
      setResizeDirection(direction);
  		setStartPos({
  			x:ev.pageX,
  			y:ev.pageY
  		});
      setIsDragging(true);
    },
    onEndResize: () => {
      setResizeDirection(null);
    },
    updateLayerZ: (direction, id) => {
      updateObjectZindex(direction, id);
    },
    updateSnapToGrid: (doSnap) => {
      setSnapToGrid(doSnap);
    },
    updateGridSize: (h, v) => {
      setGridSize({h:h, v:v});
    },
  };

  const printStyles = (layer) => {
    let styles = '';
    if (layer.customStyles !== '') {
      styles = '#' + layer.id + ' {' + layer.customStyles + '}';
    }
    if (layer.children && layer.children.length) {
      layer.children.forEach(item => {
        styles += printStyles(item);
      });
    }
    return styles;
  }

  const isResizingClass = (_resizeDirection) ? ' is-resizing' : '';

	return <mainContext.Provider value={wContext}>
    <PageHeader doc={_document} />

		<div className={"stage mode--"+_mode.toLowerCase()+isResizingClass}>

			<div className="stage__inner"
        ref={artboardRef}
				onDoubleClick={handleStageDoubleClick}
			>

				<Artboard
					doc={_document}
					objects={_visibleObjects}
					selectedObjects={_selectedObjects}
          selectedBoundary={_selectedBoundary}
					hoverObject={_hoverObject}
					resizeDirection={_resizeDirection}
					isDragging={_isDragging}
          currentGroup={_currentGroup}
					dragOffset={_dragOffset}
				/>

			</div>

			<Tools
				mode={_mode}
				updateMode={handleUpdateMode}
			/>

			<Settings
				layers={_layers}
				doc={_document}
				selectedObjects={_selectedObjects}
        selectedBoundary={_selectedBoundary}
        currentGroup={_currentGroup}
				hoverObject={_hoverObject}
      	snapToGrid={_snapToGrid}
      	gridSize={_gridSize}
				onUpdateDocument={handleUpdateDocument}
			/>

		</div>
    <style>
      {
        _document.customStyles && _document.customStyles !== '' ? _document.customStyles : null
      }
      {_layers.getAll().map(layer => {
        return printStyles(layer);
      })}
    </style>
	</mainContext.Provider>;
};

export default Stage;

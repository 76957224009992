import { getLayerObject } from '../LayerConfig.js';
import { makeid } from '../Utils.js';

export function LayerData(layers) {

	this.history = [];
	this.layers = [];

	this.historyIndex = -1;

	// init layers
	// pass array of layer objects
	this.init = (layers) => {

		this.layers = layers.map(item => {
			item.id = (item.id) ? item.id : item.type.toLowerCase() + '_' + makeid(10);
			return getLayerObject(item, item.type);
		});

		this.pushHistory();
	}

	// helpers

	this.getRelativePosition = (id) => {
		let layer = this.getByID(id);
		if (!layer) {
			return {x:0, y:0};
		}

		let pos = {x:layer.x, y: layer.y};

		let hasParent = layer.parentLayer;
		while (hasParent) {
			let p = this.getByID(hasParent);
			if (!p) {
				break;
			}

			pos.x += p.x;
			pos.y += p.y;

			hasParent = p.parentLayer;
		}

		return pos;
	}

	// get

	this.getChildren = (id) => {
		return this.layers.filter(item => item.parentLayer === id);
	}

	// get layers
	this.getAll = () => {
		let items = [];

		// map children
		this.layers.forEach((item, i) => {
			if (!item.parentLayer) {
				let layer = {...item};
				layer.children = this.getChildren(item.id);
				items.push(layer);
			}
		});

		return items;
		//return this.layers.filter(layer => layer.parentLayer === null);
	}

	// get layer by ID
	this.getByID = (id) => {
		const arr = this.layers.filter(item => item.id === id);
		if (arr.length) {
			return arr[0];
		}
		return false;
	}

	// get layer at level
	this.getAtLevel = (level) => {
		return this.layers[level];
	}

	// get layer at level
	this.levelForLayer = (id) => {
 		return this.layers.findIndex(item => item.id === id);
	}

	// add layer
	this.addLayer = (type, args, updateHistory) => {
		const layer = getLayerObject(args, type);

		layer.id = layer.type.toLowerCase() + '_' + makeid(10);
		this.layers.push(layer);

		if (updateHistory) {
			this.pushHistory();
		}

		return layer.id;
	}

	// add child layer
	this.addChildLayer = (type, args, parent, updateHistory) => {
		const layer = this.getByID(parent);

		if (layer && layer.type == 'GROUP') {

			const child = getLayerObject(args, type);
			child.id = child.type.toLowerCase() + '_' + makeid(10);
			child.parentLayer = parent;
			this.layers.push(child);

			if (updateHistory) {
				this.pushHistory();
			}

			return child.id;
		}

		return false;
	}

	// remove layer at
	this.removeLayer = (level, updateHistory) => {
		const removed = this.layers.slice(level, level+1);

		if (updateHistory) {
			this.pushHistory();
		}

		return removed;
	}

	this.swap = (from, to, updateHistory) => {
		if  (to < 0 || to > this.layers.length - 1) {
			return;
		}
		var b = this.layers[from];
		this.layers[from] = this.layers[to];
		this.layers[to] = b;

		if (updateHistory) {
			this.pushHistory();
		}
	}

	// remove layer by ID
	this.removeLayersByID = (ids) => {

		ids.forEach((id, i) => {
			// find removed
			this.layers.filter(item => item.id === id);

			// update layers with remaining
			this.layers = this.layers.filter(item => item.id !== id);
		});

		this.pushHistory();
	}

	// duplicate layer by ID
	this.duplicateLayersByID = (ids) => {

		let newIDS = [];

		ids.forEach((id, i) => {
			let layer = {...this.getByID(id)};

			// shift duplicates over so you can see them
			layer.x += 20;
			layer.y += 20;
			// layer.id = layer.id + makeid(15);
			// newIDS.push(layer.id);

			// add version number to title
			let rx = /^.*?\([^\d]*(\d+)[^\d]*\).*$/g; // checks for number between brackets (1)
			let found = rx.exec(layer.title);
			if (found) {
				let ver = parseInt(found[1]);
				layer.title = layer.title.replace('('+ver+')', '('+(ver + 1)+')');
			}
			else {
				layer.title = layer.title + ' (1)';
			}

			id = this.addLayer(layer.type, layer, false);
			newIDS.push(id);
		});

		this.pushHistory();

		return newIDS;
	}

	// update layer
	this.updateLayer = (layer, index, updateHistory) => {

		// check revision - so as to not add history steps
		if (layer.revision === this.layers[index].revision) {
			return;
		}

		this.layers[index] = layer;

		if (updateHistory) {
			this.pushHistory();
		}
	}

	// update layer by ID
	this.updateLayerByID = (layer, updateHistory) => {

		const index = this.levelForLayer(layer.id);

		// check revision - so as to not add history steps
		// if (layer.revision === this.layers[index].revision) {
		// 	return;
		// }

		this.layers[index] = layer;

		if (updateHistory) {
			this.pushHistory();
		}
	}

	// push to history
	this.pushHistory = () => {
		var newState = this.layers.map(object => ({ ...object }));

		// remove anything further that current index
		// if (this.historyIndex < this.history.length) {
		// 	this.history = this.history.slice(0,this.historyIndex + 1);
		// }

		this.historyIndex++;

		this.history.push(newState);
		//console.log('pushHistory', this.historyIndex, this.history);
	}

	// history back
	this.historyBack = () => {
		if (this.historyIndex <= 0 || this.history.length <= 0) {
			return;
		}
		this.historyIndex--;

		this.layers = this.history[this.historyIndex];
		//console.log('historyBack', this.historyIndex, this.history);
	}

	// history forward
	this.historyForward = () => {
		if (this.historyIndex + 1 >= this.history.length) {
			return;
		}
		this.historyIndex++;

		this.layers = this.history[this.historyIndex];
		//console.log('historyForward', this.historyIndex, this.history);
	}

	// save layers

	// export layers

	// init with data if set
	// if (layers) {
	// 	this.init(layers);
	// }

}

//import "./Toolbar.css";

import {
  getActiveStyles,
  getTextBlockStyle,
  toggleBlockType,
  toggleStyle,
} from "./utils/EditorUtils.js";

import { useCallback } from "react";
import { useEditor } from "slate-react";

const PARAGRAPH_STYLES = ["paragraph", "h1", "h2", "h3", "blockquote"];
//const PARAGRAPH_STYLES = ["paragraph", "h1", "h2", "h3", "h4", "blockquote", "code"];
const CHARACTER_STYLES = ["bold", "italic", "underline"];

export default function Toolbar({ selection, previousSelection }) {
  const editor = useEditor();

  const onBlockTypeChange = useCallback(
    (targetType) => {
      if (targetType === "multiple") {
        return;
      }
      toggleBlockType(editor, targetType);
    },
    [editor]
  );

  const blockType = getTextBlockStyle(editor);

  return (
    <div className="toolbar">
      {/* Dropdown for paragraph styles */}
			{PARAGRAPH_STYLES.map((blockType) => (
        <ToolBarTypeButton
          key={blockType}
          type={blockType}
          icon={<i className={`${getIconForButton(blockType)}`} />}
        />
			))}
      {/* Buttons for character styles */}
      {CHARACTER_STYLES.map((style) => (
        <ToolBarStyleButton
          key={style}
          style={style}
          icon={<i className={`${getIconForButton(style)}`} />}
        />
      ))}
    </div>
  );
}

function ToolBarTypeButton({ as, type, icon }) {
  const editor = useEditor();
  return (
    <ToolBarButton
      as={as}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlockType(editor, type);
      }}
      label={icon}
    />
  );
}

function ToolBarStyleButton({ as, style, icon }) {
  const editor = useEditor();
  return (
    <ToolBarButton
      as={as}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleStyle(editor, style);
      }}
      isActive={getActiveStyles(editor).has(style)}
      label={icon}
    />
  );
}

function ToolBarButton(props) {
  const { label, isActive, ...otherProps } = props;
  return (
    <button
      variant="outline-primary"
      className={ isActive ? "toolbar-btn active" : "toolbar-btn" }
      {...otherProps}
    >
      {label}
    </button>
  );
}

function getIconForButton(style) {
  switch (style) {
		case "h1":
			return "icon-h1";
		case "h2":
			return "icon-h2";
		case "h3":
			return "icon-h3";
		case "h4":
			return "icon-h4";
		case "paragraph":
			return "icon-paragraph";
    case "bold":
      return "icon-bold";
    case "italic":
      return "icon-italic";
    case "code":
      return "icon-code";
    case "underline":
      return "icon-underline";
    case "blockquote":
      return "icon-quote-left";
    default:
      throw new Error(`Unhandled style in getIconForButton: ${style}`);
  }
}

function getLabelForBlockStyle(style) {
  switch (style) {
    case "h1":
      return "Heading 1";
    case "h2":
      return "Heading 2";
    case "h3":
      return "Heading 3";
    case "h4":
      return "Heading 4";
    case "paragraph":
      return "Paragraph";
    case "multiple":
      return "Multiple";
    default:
      throw new Error(`Unhandled style in getLabelForBlockStyle: ${style}`);
  }
}

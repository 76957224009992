import { useContext } from 'react';
import { Tooltip } from 'react-tooltip';
import mainContext from '../context/context.js';
import { stripHTML } from '../Utils.js';

const LayerItem = ({obj, isSelected = false, isGroupSelected = false, isHovering = false, index = 0, max = 1, level = 1}) => {

  const { onObjectHover, onObjectSelect, onLayerDblClick, updateLayerZ } = useContext(mainContext);
	const isSelectedClass = (isSelected) ? ' is-selected' : '';
	const isGRoupSelectedClass = (isGroupSelected) ? ' group-selected' : '';
	const isHoveringClass = (isHovering) ? ' is-hovering' : '';

  const indentClass = (level > 0) ? ' layer--indent' : '';

	return <div
		className={"layer layer--"+obj.type.toLowerCase()+isSelectedClass+isGRoupSelectedClass+isHoveringClass+indentClass}
		onMouseDown={() => { onObjectSelect(obj.id) }}
		onMouseEnter={() => { onObjectHover(obj.id) }}
		onMouseLeave={() => { onObjectHover(null) }}
		onDoubleClick={() => { onLayerDblClick(obj.id, obj.type) }}
	>
		<div className="layer__header">
			<div className="layer__info">
				<div className="layer__title">{obj.title}</div>

        {
          obj.type === 'TEXT' && <div className="layer__copy">{stripHTML(obj.TEXT.value)}</div>
        }
			</div>
			<div className="layer__actions">

				<div className="btn-wrap">

					<button
						className="btn btn-inline btn-toggle-editable"
					>
						<i className="icon-edit"></i><span className="visually-hidden">Move layer up</span>
					</button>
					<button
						className="btn btn-inline btn-move-up"
            disabled={index === max - 1}
            onClick={() => { updateLayerZ(1, obj.id) }}
					>
						<i className="icon-angle-up"></i><span className="visually-hidden">Move layer up</span>
					</button>
					<button
						className="btn btn-inline btn-move-down"
            disabled={index === 0}
            onClick={() => { updateLayerZ(-1, obj.id) }}
					>
						<i className="icon-angle-down"></i><span className="visually-hidden">Move layer down</span>
					</button>
					<button
						className="btn btn-inline btn-move-down"
					>
						<i className="icon-ellipsis-vertical"></i><span className="visually-hidden">Move layer down</span>
					</button>

				</div>

			</div>
		</div>
	</div>;
};

export default LayerItem;

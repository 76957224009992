import { useState, useEffect } from "react";
import { Tooltip } from 'react-tooltip';

const Tools = ({ mode = 'SELECT', updateMode }) => {

	const [_mode, setMode] = useState(mode);

	useEffect(() => {
		setMode(mode);
	}, [mode]);

	const buttons = [
		{
			type: 'SELECT',
			label: 'Select',
			icon: 'icon-mouse-pointer'
		},
		{
			type: 'TEXT',
			label: 'Add Text Layer',
			icon: 'icon-font'
		},
		{
			type: 'IMAGE',
			label: 'Add Image',
			icon: 'icon-image'
		},
		{
			type: 'SHAPE',
			label: 'Add Shape',
			icon: 'icon-draw-square'
		},
		{
			type: 'GROUP',
			label: 'Add Group',
			icon: 'icon-layer-group'
		},
		// {
		// 	type: 'QUOTE',
		// 	label: '',
		// 	icon: ''
		// },,
		// {
		// 	type: 'ICON',
		// 	label: '',
		// 	icon: ''
		// },
		// {
		// 	type: 'GROUP', // div?
		// 	label: '',
		// 	icon: ''
		// },
		// {
		// 	type: 'VIDEO',
		// 	label: '',
		// 	icon: ''
		// }
	];

	const handleClick = (type) => {
		setMode(type);
		if (updateMode) {
			updateMode(type);
		}
	}

	return <div className="tools">
		<div className="tools__inner">
			<ul>
				{buttons.map(item => {
					return <li key={item.type}>
						<button
							id={'btn-' + item.type.toLowerCase()}
							onClick={ () => { handleClick(item.type) } }
							className={(_mode === item.type) ? 'active' : null }
						>
							<i className={item.icon}></i>
						</button>
						<Tooltip anchorId={'btn-' + item.type.toLowerCase()} content={item.label} place="right" />
					</li>
				})}
			</ul>
		</div>
	</div>;
};

export default Tools;

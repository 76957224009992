import { useState, useEffect } from "react";

const SettingsPanel = ({title = '', isOpen = false, children, onUpdateOpen}) => {

	const [_isOpen, setIsOpen] = useState(isOpen);

  useEffect(() => {
	  setIsOpen(isOpen);
  }, [isOpen]);

	const toggleOpen = () => {
		onUpdateOpen(!_isOpen);
	}

	return <div className={(_isOpen) ? "s-panel is-open" : "s-panel"}>
		<div className="s-panel__header" onClick={toggleOpen}>
			<span>{title}</span>
			<div className="s-panel__handle"><i className="icon-angle-down"></i></div>
		</div>
		<div className="s-panel__content" style={{display:(_isOpen) ? 'block' : 'none'}}>
			{ children }
		</div>
	</div>;
};

export default SettingsPanel;

import { DefaultElement } from "slate-react";
import { toggleStyle } from "../utils/EditorUtils.js";
import StyledText from "../components/StyledText.js";

import isHotkey from "is-hotkey";

export default function useEditorConfig(editor) {
	const { isVoid } = editor;
  editor.isVoid = (element) => {
    return ["image"].includes(element.type) || isVoid(element);
  };

  editor.isInline = (element) => ["link"].includes(element.type);

  return { renderElement, renderLeaf, KeyBindings };
}

function renderElement(props) {
  const { element, children, attributes } = props;

  switch (element.type) {
    case "paragraph":
      return (
        <p {...attributes} content-editable={"true"}>
          {children}
        </p>
      );
    case "h1":
      return (
        <h1 {...attributes} content-editable={"true"}>
          {children}
        </h1>
      );
    case "h2":
      return (
        <h2 {...attributes} content-editable={"true"}>
          {children}
        </h2>
      );
    case "h3":
      return (
        <h3 {...attributes} content-editable={"true"}>
          {children}
        </h3>
      );
    case "h4":
      return (
        <h4 {...attributes} content-editable={"true"}>
          {children}
        </h4>
      );
    // case "link":
    //   return (
    //     <a {...attributes} href={element.href} rel={element.rel} title={element.title} target={element.target} erdgf={element.erdgf} content-editable={"true"}>
    //       {children}
    //     </a>
    //   );
		case "blockquote":
			return (
				<blockquote {...attributes} content-editable={"true"}>
					{children}
				</blockquote>
			);
		case "cite":
			return (
				<cite {...attributes} content-editable={"true"}>
					{children}
				</cite>
			);
		case "figure":
			return (
				<figure {...attributes} {...element} content-editable={"true"}>
					{children}
				</figure>
			);
		case "iframe":
			return (
				<iframe {...attributes} {...element} content-editable={"true"}>
					{children}
				</iframe>
			);
		case "code":
			return (
				<pre {...attributes} content-editable={"true"}>
					<code>{children}</code>
				</pre>
			);
		case "img":
			return (
				<img {...attributes} src={element.src} width={element.width} height={element.height} alt={element.alt}  />
			);
		case "figcaption":
			return (
				<figcaption {...attributes} content-editable={"true"}>
					{children}
				</figcaption>
			);
    default:
      return <DefaultElement {...props} />;
  }
}

function renderLeaf(props) {
  return <StyledText {...props} />;
}

const KeyBindings = {
  onKeyDown: (editor, event) => {
    if (isHotkey("mod+b", event)) {
      toggleStyle(editor, "bold");
      return;
    }
    if (isHotkey("mod+i", event)) {
      toggleStyle(editor, "italic");
      return;
    }
    // if (isHotkey("mod+k", event)) {
    //   toggleStyle(editor, "code");
    //   return;
    // }
    if (isHotkey("mod+u", event)) {
      toggleStyle(editor, "underline");
      return;
    }
  },
};

import { useState, useEffect, useContext } from "react";
import { Tooltip } from 'react-tooltip';
import mainContext from '../../context/context.js';

const SettingsGroup = ({layer, doc}) => {


	return <div className="content">
		<div className="flex flex--wrap">

      <div className="col-12">
        <div className="form-row">
          <label htmlFor="size">Group setting</label>


        </div>
      </div>

		</div>
	</div>;
};

export default SettingsGroup;

import { useState, useEffect, useContext } from "react";
import mainContext from '../../context/context.js';

const ResizeTools = ({ boundry, isDragging = false, isResizing = false }) => {

  const { onStartResize } = useContext(mainContext);

	return <div className="resize-tools" style={{
		top:boundry.y,
		left:boundry.x,
		width:boundry.width,
		height:boundry.height,
	}}>
		<div className="resize-tools__inner">

			<button className="rt rt--t" onMouseDown={ (ev) => { onStartResize('n',ev) } }></button>
			<button className="rt rt--b" onMouseDown={ (ev) => { onStartResize('s',ev) } }></button>
			<button className="rt rt--l" onMouseDown={ (ev) => { onStartResize('w',ev) } }></button>
			<button className="rt rt--r" onMouseDown={ (ev) => { onStartResize('e',ev) } }></button>
			<button className="rt rt--tl" onMouseDown={ (ev) => { onStartResize('nw',ev) } }></button>
			<button className="rt rt--tr" onMouseDown={ (ev) => { onStartResize('ne',ev) } }></button>
			<button className="rt rt--bl" onMouseDown={ (ev) => { onStartResize('sw',ev) } }></button>
			<button className="rt rt--br" onMouseDown={ (ev) => { onStartResize('se',ev) } }></button>

      {isDragging && <>
        <span className="lbl lbl--xy">x:<span>{boundry.x}px</span> y:<span>{boundry.y}px</span></span>
      </>}

      {isResizing && <>
        <span className="lbl lbl--width">{boundry.width}px</span>
        <span className="lbl lbl--height">{boundry.height}px</span>
      </>}

		</div>
	</div>

}

export default ResizeTools;

import { useEffect, useState } from "react";
import { BlockPicker } from 'react-color';
// import { ChromePicker } from 'react-color';

const ColorPicker = ({color, colors = [], onChangeComplete}) => {

	// const hexToRgba = ({hex, alpha = 1}) => {
	//
	// 	if (hex === 'transparent') {
	// 		return {r:255,g:255,b:255,a:0}
	// 	}
	//
	//   let r = parseInt(hex.substring(0,2), 16);
	//   let g = parseInt(hex.substring(2,4), 16);
	//   let b = parseInt(hex.substring(4,6), 16);
	//
	// 	return {r:r,g:g,b:b,a:alpha}
	// }

	const [_color, setColor] = useState(color);
	const [_show, setShow] = useState(false);

	const handleColorPick = (color) => {
		setColor(color.hex);

		if (onChangeComplete) {
			onChangeComplete(color.hex);
		}
	}

	useEffect(() => {
		setColor(color);
	}, [color]);

	return (
		<div className="color-picker">
			<div className="color-picker__swatch" onClick={ () => { setShow(true); } }>
				<div style={{ backgroundColor:_color}} />
			</div>
			{ _show ? <div className="color-picker__popover">
				<div className="color-picker__clicktrap" onClick={ () => { setShow(false); } }></div>
				<BlockPicker
					color={ _color }
					colors={colors}
					triangle="hide"
					onChangeComplete={ handleColorPick }
				/>
			</div> : null }
		</div>
	)
}
export default ColorPicker;

import { useState, useEffect, useContext } from "react";
import mainContext from '../context/context.js';
import logoSVG from '../images/logo-bm.svg';

const PageHeader = ({doc}) => {

	const { onSave, onPreview } = useContext(mainContext);

	return <header className="page-header">
		<div className="page-header__inner">

			<div className="logo">
				<img src={logoSVG} />
			</div>
			<div className="link-back"></div>
			<div className="doc-meta">
				<div className="doc-meta__doctype">{doc.doctype}</div>
				<div className="doc-meta__title">{doc.title}</div>
			</div>
			<div className="actions">
				<div className="btn-wrap">
					<button className="btn btn-preview btn-blue" onClick={() => { onPreview() }}><i className="icon-picture"></i> Preview</button>
					<button className="btn btn-save btn-green" onClick={() => { onSave() }}><i className="icon-floppy"></i> Save</button>
					{ /*
					<button className="btn btn-publish btn-pink"><i className="icon-rocket"></i> Publish</button>
					*/ }
				</div>
			</div>

		</div>
	</header>;
};

export default PageHeader;

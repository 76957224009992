// import { useState, useEffect, useContext } from "react";

const ResizeTools = ({ boundry }) => {

	return <div className="guide-lines">

		<div className="gl--v1" style={{left:boundry.x}}></div>
		<div className="gl--v2" style={{left:boundry.x + boundry.width - 1}}></div>
		<div className="gl--h1" style={{top:boundry.y}}></div>
		<div className="gl--h2" style={{top:boundry.y + boundry.height - 1}}></div>

	</div>

}

export default ResizeTools;

import { useRef, useEffect } from "react";

/* Ramdom string */
export const makeid = (length) => {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';//'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


/* String */
export const capitalise = (str) => {
  const word = str.toLowerCase()
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const stripHTML = (str) => {
  return str.replace(/(<([^>]+)>)/gi, "");
}


/* Formats */
// date
export const date = (str) => {

	const date = new Date(str);

	return (
    `${('0' + date.getDate()).slice(-2)}/` +
    `${('0' + (date.getMonth() + 1)).slice(-2)}/` +
    `${('' + date.getFullYear()).slice(2)} `
  )
}
export const dateTime = (str) => {

	const date = new Date(str);

	return (
    `${('0' + date.getDate()).slice(-2)}/` +
    `${('0' + (date.getMonth() + 1)).slice(-2)}/` +
    `${('' + date.getFullYear()).slice(2)} ` +
    `${('0' + date.getHours()).slice(-2)}:` +
    `${('0' + date.getMinutes()).slice(-2)}`
  )
}

export const dateFormat = (str) => {

	const days = [
	  'Sun',
	  'Mon',
	  'Tue',
	  'Wed',
	  'Thu',
	  'Fri',
	  'Sat'
	];
	const months = {
	  0: 'January',
	  1: 'February',
	  2: 'March',
	  3: 'April',
	  4: 'May',
	  5: 'June',
	  6: 'July',
	  7: 'August',
	  8: 'September',
	  9: 'October',
	  10: 'November',
	  11: 'December'
	}

	const d = new Date(str);
	const year = d.getFullYear();
	const date = d.getDate();
	const monthName = months[d.getMonth()];
	const dayName = days[d.getDay()];

	return `${dayName}, ${date} ${monthName} ${year}`;
};

// currency
export const currencyFormat = (num, symbol, inPence = false) => {
	symbol = symbol || '£';
	if (inPence) {
		num = num / 100; // convert to pounds
	}
  return symbol + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

// dates
export const timeSince = (d) => {
	const date = new Date(d);
	const today = new Date();
	const dif = (today.getTime() - date.getTime());

	// right now
	if (dif < 1000) {
    return 'right now'
  }
	// > 1 hour
	else if (dif <= 3600000) {
		const minutes = Math.floor(dif / (60*1000));
		//return (minutes > 1) ? minutes + ' mins ago' : minutes + ' min ago';
		return `${minutes} min${(minutes > 1) ? 's' : ''} ago`
	}
	// hours
	else if (dif <= 43200000) {
		const hours = Math.floor(dif / (60*60*1000));
		//return (hours > 1) ? hours + ' hours ago' : hours + ' hour ago';
		return `${hours} hour${(hours > 1) ? 's' : ''} ago`;
	}
	// days
	else if (dif <= 172800000) {
		const days = Math.round(dif / (60*60*24*1000));
		//return (days > 1) ? days + ' days ago' : days + ' day ago';
		return `${days} day${(days > 1) ? 's' : ''} ago`;
	}

	return (
    `${('0' + date.getDate()).slice(-2)}/` +
    `${('0' + (date.getMonth() + 1)).slice(-2)}/` +
    `${('' + date.getFullYear()).slice(2)} ` +
    `${('0' + date.getHours()).slice(-2)}:` +
    `${('0' + date.getMinutes()).slice(-2)}`
  )
}

// data
export const bytesToSize = (bytes) => {
   var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
   if (bytes == 0) return '0 Byte';
   var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
   return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

// colours
export const hexToRgba = (hex, alpha) => {
  hex = hex.replace('#','');
  if (hex.length === 3) {
    hex = hex.split('').map(function (h) {
  		return h + h;
  	}).join('');
  }
  let r = parseInt(hex.substring(0,2), 16);
  let g = parseInt(hex.substring(2,4), 16);
  let b = parseInt(hex.substring(4,6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// general

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
}

// validation
function supportsValidity(){
  var i = document.createElement('input');
  return typeof i.validity === 'object';
}

function strip(html) {
	var tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
}

export const validate = (str, type) => {
	var type = type || 'strip';
	var pString = /^[a-zA-Z]+$/;
	var pName = /^[a-zA-Z ,.'-]+$/i;
	var pNumber = /^\d+$/;
	var pEmail = /[a-z0-9!#$%&'*+\/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9-]*[a-z0-9]/;
	var pPhone = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
	var pURL = /((https?|ftp|file|ssh):\/\/)?(((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?/;

	switch (type) {
		case 'strip': {
			return strip(str);
		}
		case 'phone': {
			str = (pPhone.test(str)) ? str : '';
			return str;
		}
  		case 'letters': {
  			str = (pString.test(str)) ? str : '';
  			return str;
  		}
  		case 'name': {
  			str = (pName.test(str)) ? str : '';
  			return str;
  		}
		case 'number': {
			str = (pNumber.test(str)) ? str : '';
			return str;
		}
		case 'email': {
			str = (pEmail.test(str)) ? str : '';
			return str;
		}
		case 'url': {
			str = (pURL.test(str)) ? str : '';
			return str;
		}
	}
}

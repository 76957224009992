import { useEffect, useCallback, useMemo, useRef } from "react";
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import useEditorConfig from "./hooks/useEditorConfig.js";
import useSelection from "./hooks/useSelection.js";
import { toHTML } from "./utils/EditorUtils.js";

import Toolbar from './Toolbar.js';

const Editor = ({ document, onChange, className }) => {

	const editorRef = useRef(null);
  const editor = useMemo(() => withReact(createEditor()), []);
  const { renderLeaf, renderElement, KeyBindings } = useEditorConfig(editor);

  const onKeyDown = useCallback(
    (event) => KeyBindings.onKeyDown(editor, event),
    [KeyBindings, editor]
  );

  const [previousSelection, selection, setSelection] = useSelection(editor);

  // we update selection here because Slate fires an onChange even on pure selection change.
  const onChangeLocal = useCallback(

    (doc) => {
				//console.log(toHTML(doc));
      onChange(doc, toHTML(doc));
      setSelection(editor.selection);
    },
    [onChange, setSelection, editor]
  );

	useEffect(() => {
		editor.children = document
	}, [document]);

	return (
		<div className={ (className) ? "editor-wrap "+className : "editor-wrap"}>
			<Slate
				editor={editor}
				value={document}
				onChange={onChangeLocal}
			>
				<Toolbar
          selection={selection}
          previousSelection={previousSelection}
        />
        <div ref={editorRef}>
					<Editable
						className="editor"
						renderElement={renderElement}
						renderLeaf={renderLeaf}
	          onKeyDown={onKeyDown}
					/>
				</div>
			</Slate>
		</div>
	)
}
export default Editor;
